<template>
  <v-navigation-drawer temporary app :value="opened" @input="update" dark>
    <v-toolbar flat dense>
      <v-toolbar-title class="title font-weight-light text-capitalize">
        <img :src="require('@/assets/logo.png')" class="appLogo" />
        Safety Evolution
      </v-toolbar-title>
      <v-spacer />
      <v-btn @click="update(false)" icon text>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider />

    <v-list dense class="pt-0">
      <NavDrawerItem :item="navItems.home">
        <template v-slot:indicator v-if="hasProblems">
          <v-icon color="orange" small>mdi-alert-circle-outline</v-icon>
        </template>
      </NavDrawerItem>
      <NavDrawerItem :item="navItems.reminders" />
      <NavDrawerItem :item="navItems.meetings" />
      <NavDrawerItem :item="navItems.flhas" />
      <NavDrawerItem :item="navItems.inspections" />
      <NavDrawerItem :item="navItems.contacts" />
      <v-divider />
      <SignOut class="mt-1" />
      <NavDrawerItem :item="navItems.bugReport" />
      <NavDrawerItem :item="navItems.remoteDebug" />
      <NavDrawerItem :item="navItems.clearAllData" />
      <div class="font-weight-light grey--text caption ma-2">
        version: {{ $version }}<br />
        build date: {{ buildDate.toLocaleDateString() }}<br />
        build time: {{ buildDate.toLocaleTimeString() }}<br />
        storage: {{ storageInfo }}
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import SignOut from "@/components/SignOut.vue";
import bugsnagClient from "@/bugsnag.js";
import NavDrawerItem from "./NavDrawerItem";
import emitter from "@/events";

export default {
  components: { SignOut, NavDrawerItem },
  data() {
    return {
      navItems: {
        home: {
          text: "Home",
          icon: "fa-tachometer-alt",
          action: { path: "/" }
        },
        remoteDebug: {
          text: "Enable Remote Debug",
          icon: "fa-bug",
          action: { handler: this.enableRemoteDebug }
        },
        bugReport: {
          text: "Report Issue",
          icon: "fa-bug",
          action: { handler: this.collectBugReport }
        },
        clearAllData: {
          text: "Reset Application",
          icon: "fa-exclamation-triangle",
          action: { handler: this.confirmClearData }
        },
        reminders: {
          text: "Reminders",
          icon: "fa-calendar",
          action: { path: "/reminders" }
        },
        contacts: {
          text: "Contacts",
          icon: "fa-address-book",
          action: { path: "/contacts" }
        },
        meetings: {
          text: "Safety Meetings",
          icon: "fa-handshake",
          action: { path: "/safetymeetings" }
        },
        flhas: {
          text: "FLHAs",
          icon: "fa-exclamation-triangle",
          action: { path: "/flhas" }
        },
        inspections: {
          text: "Inspections",
          icon: "fa-check-circle",
          action: { path: "/inspections" }
        }
      },
      storage: {}
    };
  },
  computed: {
    opened() {
      return this.$store.getters.showNavDrawer;
    },
    buildDate() {
      let bd = this.$version.split(".")[3];
      return new Date(Number(bd));
    },
    hasProblems() {
      return this.$store.getters["problems/records"].length > 0;
    },
    storageInfo() {
      if (this.storage.usage) {
        const { usage, quota } = this.storage;
        return `${((usage / quota) * 100).toFixed(0)}% used.`;
      }

      return "Unknown";
    }
  },
  methods: {
    update(state) {
      this.$store.commit("setNavDrawerState", state);
    },
    confirmClearData() {
      this.update(false);
      emitter.emit("showClearAllDialog");
    },
    collectBugReport() {
      let issueDescription = window.prompt("Describe the issue.");
      if (!issueDescription) return;

      bugsnagClient.notify(issueDescription);
      this.$store.commit("snackbar/success", "Issue reported.");
    },
    enableRemoteDebug() {
      if (window.remoteDebugOn) {
        window.alert("Remote debugging already enabled.");
        return false;
      }

      if (window.confirm("Are you sure?")) {
        window.remoteDebugOn = true;
        const s = document.createElement("script");
        s.src = "https://remotejs.com/agent/agent.js";
        s.setAttribute(
          "data-consolejs-channel",
          "55579209-34ee-f7b9-fa9a-93489aff409c"
        );

        document.head.appendChild(s);
      }
    }
  },
  created() {
    if (window?.navigator?.storage?.estimate) {
      window.navigator.storage
        .estimate()
        .then(estimate => (this.$data.storage = estimate));
    }
  }
};
</script>

<style>
.v-navigation-drawer {
  max-width: calc(100vw - 60px);
}

.theme--dark.v-navigation-drawer {
  background-color: #2f4050;
}

.theme--dark.v-navigation-drawer .v-list .primary--text .v-list__tile__content {
  color: #74b635 !important;
}

.v-navigation-drawer .theme--dark.v-toolbar {
  background-color: #2f4050;
}

.v-navigation-drawer .theme--dark.v-icon {
  color: #a7b1c2;
}

.v-navigation-drawer .appLogo {
  position: relative;
  top: 3px;
  left: 2px;
  height: 6mm;
  margin-right: 2mm;
}
</style>
