<template>
  <div class="px-3 pb-4">
    <v-dialog
      fullscreen
      scrollable
      :value="true"
      v-if="editingSignon"
      ref="sigDialog"
      @keydown.esc="dismissDialog"
    >
      <v-card height="100%">
        <app-toolbar title="Add Signature" :app="false">
          <template v-slot:right>
            <v-btn icon>
              <v-icon @click="dismissDialog">mdi-close</v-icon>
            </v-btn>
          </template>
        </app-toolbar>
        <SignatureCard :signon="editingSignon" @done="saveSignon" />
      </v-card>
    </v-dialog>
    <div v-for="signon in signons" :key="signon.url" class="sig-list">
      <v-btn icon small @click.prevent="removeSignon(signon)" class="delete">
        <v-icon color="red lighten-2">mdi-delete</v-icon>
      </v-btn>
      <img
        :src="signon.url"
        @click="editSignon(signon)"
        crossorigin="anonymous"
        class="mt-2"
      />
      <div>{{ signon.name }}</div>
      <v-divider />
    </div>

    <v-btn color="primary" dark fab small fixed bottom right @click="newSignon"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </div>
</template>

<script>
import SignatureCard from "@/components/SignatureCard";

export default {
  props: ["recordId", "recordType", "storeName"],
  components: { SignatureCard },
  data() {
    return {
      editingSignon: false
    };
  },
  computed: {
    signons() {
      return this.$store.getters[`signons/forRecord`]({
        id: this.recordId,
        type: this.recordType
      });
    }
  },
  methods: {
    saveSignon({ name, employeeId, url }) {
      this.$store.dispatch("signons/insert", {
        ...this.editingSignon,
        employeeId,
        url,
        name
      });
      this.dismissDialog();
    },
    newSignon() {
      this.editingSignon = {
        signonableId: this.recordId,
        signonableType: this.recordType
      };
    },
    editSignon(signon) {
      this.editingSignon = signon;
    },
    removeSignon(signon) {
      this.$store.dispatch("signons/destroy", signon.id);
    },
    dismissDialog() {
      this.editingSignon = false;
    }
  }
};
</script>

<style scoped>
.sig-list {
  position: relative;
}

.actions {
  position: absolute;
  right: 1px;
}

img {
  max-width: calc(100vw - 32px);
}

.delete {
  position: absolute;
  right: -6px;
  top: 6px;
}
</style>
