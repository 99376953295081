export default {
  data() {
    return {
      onLine: navigator.onLine
    };
  },
  methods: {
    updateConnectionStatus() {
      this.onLine = navigator.onLine;
    }
  },
  created() {
    window.addEventListener("online", this.updateConnectionStatus);
    window.addEventListener("offline", this.updateConnectionStatus);
  }
};
