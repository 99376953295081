<template>
  <v-card color="lighten-2" dark class="my-2">
    <v-card-text>
      <div class="mb-2">
        Attachment {{ problemRecord.fileName }} on
        <router-link :to="linkPath" class="text-uppercase">{{
          problemRecord.reportType
        }}</router-link>
      </div>
      <span class="font-weight-thin">Problems</span>
      <div>
        {{ JSON.stringify(problem.errors) }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />

      <v-btn small color="red" @click="fail">Fail</v-btn>
      <v-btn small color="green" @click="retry">Retry</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SignonAttachmentProblemMixin from "./signon_attachment_problem_mixin";

const typeField = "reportType";
const idField = "reportId";
const problemTypePluralized = "form_attachments";

export default {
  props: ["problem"],
  mixins: [
    SignonAttachmentProblemMixin({
      typeField,
      idField,
      problemTypePluralized
    })
  ]
};
</script>
