<template>
  <div>
    <v-card
      v-for="taskStep in taskSteps"
      :key="taskStep.id"
      class="my-3"
      elevation="0"
    >
      <v-card-text class="pa-2">
        <h5 class="font-weight-light">Description</h5>
        <div>{{ taskStep.description }}</div>

        <h5 class="font-weight-light mt-3">Hazards</h5>
        <div class="mt-1">{{ taskStep.hazards }}</div>

        <v-row no-gutters class="mt-3">
          <v-col>
            <h5>
              <span class="font-weight-light">Severity:</span>
            </h5>
            <div>{{ severities[taskStep.severity] }}</div>
          </v-col>
          <v-col>
            <h5>
              <span class="font-weight-light">Probability:</span>
            </h5>
            <div>{{ probabilities[taskStep.probability] }}</div>
          </v-col>
        </v-row>

        <h5 class="mt-3 font-weight-light">Precautions Taken</h5>
        <p>{{ taskStep.precautionsTaken }}</p>

        <v-row no-gutters class="mt-3">
          <v-col>
            <h5>
              <span class="font-weight-light">Severity:</span>
            </h5>
            <div>{{ severities[taskStep.mitigatedSeverity] }}</div>
          </v-col>
          <v-col>
            <h5>
              <span class="font-weight-light">Probability:</span>
            </h5>
            <div>{{ probabilities[taskStep.mitigatedProbability] }}</div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="showActions">
        <v-spacer />
        <v-btn small icon color="warning" @click="confirmDelete(taskStep.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn small icon @click="$emit('click', taskStep)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider />
    </v-card>

    <div class="text-center">
      <v-dialog v-model="dialog.show" width="500">
        <v-card>
          <v-card-title class="">
            Confirm
          </v-card-title>

          <v-card-text>
            Delete Task Step?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog.show = false">
              Cancel
            </v-btn>
            <v-btn color="red" text @click="deleteTaskStep">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
const severities = {
  1: "Minor",
  2: "Moderate",
  3: "Serious",
  4: "Major",
  5: "Catastrophic"
};

const probabilities = {
  1: "Improbable",
  2: "Remote",
  3: "Occasional",
  4: "Probable",
  5: "Frequent"
};

export default {
  props: {
    taskSteps: {
      required: true
    },
    showActions: {
      default: false
    }
  },
  data() {
    return {
      severities,
      probabilities,
      dialog: {
        show: false,
        taskStepId: null
      }
    };
  },
  methods: {
    confirmDelete(taskStepId) {
      this.dialog.taskStepId = taskStepId;
      this.dialog.show = true;
    },
    deleteTaskStep() {
      this.$store.dispatch("taskSteps/destroy", this.dialog.taskStepId);
      this.dialog.show = false;
    }
  }
};
</script>
