import uuidv4 from "uuid/v4";

export default function Flha() {
  const timestamp = new Date();
  const record = {
    id: uuidv4(),
    createdAt: timestamp.toISOString(),
    updatedAt: timestamp.toISOString(),
    lockedAt: null,
    siteId: null,
    location: null,
    date: timestamp.toISOString().substr(0, 10),
    workPlanned: null,
    windDirection: null,
    weatherConditions: null,
    musterPoint: null,
    workingAlone: null,
    workingAloneExplained: null,
    ppeInspected: null,
    ribbon: null,
    preUseInspection: null,
    reviewFollowup: {
      first_break: null,
      lunch_break: null,
      third_break: null
    },
    allPermitsClosedOut: null,
    workAreaCleaned: null,
    hazardsRemaining: null,
    hazardsRemainingExplained: null,
    incidents: null,
    incidentsExplained: null,
    hazardChoiceIds: []
  };

  return record;
}
