import seLog from "@/common/seLog";

// This setup function takes the Vue app instance as a parameter
export function setup(vm) {
  seLog("autosync:  In setup()");

  if (typeof vm.autosync != "undefined") {
    seLog("autosync:  skipping setup as we've already been called before");
    return;
  }

  vm.autosync = {
    retryInterval: 0, // how long to wait before trying to sync again when having connectivity issues
    waiting: 0, // Used to hold the setTimeoutInterval id when we're having connectivity issues
    inProgress: false,
    killWatcher: 0
  };

  vm.autosync.resetWaiting = function() {
    seLog("autosync:  in resetWaiting");
    clearTimeout(vm.autosync.waiting);
    vm.autosync.waiting = 0;
  };

  vm.autosync.reset = function() {
    seLog("autosync:  in reset");
    vm.autosync.resetWaiting();
    vm.autosync.inProgress = false;
  };

  vm.autosync.validate = function(force = false) {
    let readyToSync;
    try {
      readyToSync = vm.$store.getters["autosync/readyToSync"]; // -1 = offline, 0+ = # of items in autosync queue
    } catch {
      readyToSync = -2; // no store or autosync module
    }

    // This console-logging if block will be deleted or commented out after implementation is considered solid
    if (readyToSync == -2) {
      seLog(
        "autosync:  Skipping request as there is no store or no autosync extension in the store."
      );
    } else if (readyToSync == -1) {
      seLog(
        "autosync:  We're currently offline. Aborting this and future autosync attempts while offline."
      );
    } else if (vm.autosync.inProgress) {
      seLog("autosync:  Skipping request as we're already in progress!");
    } else {
      if (!force && vm.autosync.waiting) {
        seLog(
          "autosync:  Waiting a bit before trying the network call again... Aborting autosync attempt."
        );
      } else if (readyToSync == 0) {
        seLog("autosync:  empty queue. Nothing to do!");
      }
    }
    return (
      readyToSync > 0 &&
      !vm.autosync.inProgress &&
      (!vm.autosync.waiting || force)
    );
  };

  vm.autosync.getNextItem = function() {
    return vm.$store.state.autosync.queue[0];
  };

  // The force parameter will attempt to process the queue regardless of any retry timeouts. It will not attempt if we're offline.
  vm.autosync.sync = async function(options = {}) {
    const defaultOptions = { force: false, trigger: "None specified" };
    options = { ...defaultOptions, ...options };

    seLog(`autosync:  processing queue, triggered by = ${options.trigger}`);

    if (!vm.autosync.validate(options.force)) {
      return;
    }

    vm.autosync.inProgress = true;

    const { store, action, id } = vm.autosync.getNextItem();
    seLog(`autosync:  processing queue item => ${store}, ${action}, ${id}`);

    try {
      await vm.$store.dispatch(`${store}/${action}`, id);
      seLog(`autosync:  dequeue => ${store}, ${action}, ${id}`);
      vm.autosync.reset();
      vm.autosync.retryInterval = 0;
      vm.$store.dispatch("autosync/dequeue", id);
    } catch (error) {
      seLog(`autosync:  logging try => ${store}, ${action}, ${id}`);
      vm.$store.dispatch("autosync/tried", { id, result: error });
      seLog("autosync:  aborting remainder of queue");
      seLog(`autosync:  reason = ${error}`, error);
      vm.autosync.retryInterval = Math.min(
        vm.autosync.retryInterval * 2 + 1000,
        300000
      );
      vm.autosync.inProgress = false;
      seLog(
        `autosync:  scheduling retry in ${vm.autosync.retryInterval} mS...`
      );
      vm.autosync.waiting = setTimeout(
        () => vm.autosync.sync({ force: true, trigger: "Re-try timer" }),
        vm.autosync.retryInterval
      );
    }
  };
  setupWatcher(vm);
  vm.autosync.reset();
  vm.autosync.sync({ trigger: "App launch" });
}

function setupWatcher(vm) {
  seLog("autosync:  Setting up watcher");
  vm.$store.dispatch("autosync/setupOnlineStatusEvents");
  vm.autosync.killWatcher = vm.$store.watch(
    (state, getters) => getters["autosync/readyToSync"],
    (newValue, oldValue) => {
      seLog(
        `autosync:  detected queue or online status change. old=${oldValue}, new=${newValue}`
      );
      if (
        newValue > 0 &&
        (oldValue == 0 || // adding first element to queue while online
        oldValue < 0 || // to-online transition with non-empty queue
          oldValue > newValue) // dequeued an item with items still remaining
        // intentionally excluding firing when adding element to a non-empty queue (oldValue > 0 && newValue > oldValue)
        // and anything happening when unavailable or offline (newValue < 0) or empty (newValue == 0)
      ) {
        vm.autosync.reset();
        vm.autosync.sync({
          trigger: "Detected non-empty queue and online"
        });
      }
    }
  );
}
