<template>
  <v-layout fill-height>
    <app-toolbar :backButton="true" title="Viewing FLHA" />
    <v-flex v-if="record">
      <v-card class="pa-2" height="100%">
        <v-card-text class="pa-0 ma-0">
          <div class="subheading">
            FLHA<br />
            <span class="text-no-wrap grey--text text-darken-4">{{
              siteName(record.siteId)
            }}</span>
            <span v-if="record.location" class="font-weight-light">
              &mdash; {{ record.location }}</span
            >
          </div>
          <div class="red--text text--lighten-2 mt-1">
            {{ record.date | date }}
          </div>

          <dl class="mt-1">
            <template v-if="record.location">
              <dt>Location</dt>
              <dd>{{ record.location }}</dd>
            </template>
            <template v-if="record.createdBy">
              <dt>Lead</dt>
              <dd>{{ record.createdBy }}</dd>
            </template>

            <dt>Created</dt>
            <dd>{{ record.createdAt | date("DATETIME_MED") }}</dd>

            <dt>Updated</dt>
            <dd>{{ record.updatedAt | date("DATETIME_MED") }}</dd>

            <dt>Workers</dt>
            <dd>{{ workerNames }}</dd>

            <dt>Work Planned</dt>
            <dd>{{ record.workPlanned }}</dd>

            <dt>Weather</dt>
            <dd>{{ record.weatherConditions }}</dd>

            <dt>Wind Direction</dt>
            <dd>{{ record.windDirection }}</dd>

            <dt>Muster Point</dt>
            <dd>{{ record.musterPoint }}</dd>

            <dt>Working Alone?</dt>
            <dd>
              {{ yesNo(record.workingAlone) }}
              <span v-if="record.workingAlone === true">
                --
                {{ record.workingAloneExplained }}
              </span>
            </dd>

            <dt>P.P.E. Inspected?</dt>
            <dd>{{ yesNo(record.ppeInspected) }}</dd>

            <dt>Ribbon Needed?</dt>
            <dd>{{ yesNo(record.ribbon) }}</dd>

            <dt>Equipment Pre-Use Inspection Done?</dt>
            <dd>{{ yesNo(record.preUseInspection) }}</dd>

            <dt>Permit Number</dt>
            <dt>{{ record.permitNumber }}</dt>
          </dl>

          <v-divider />
          <div class="font-weight-light my-2">
            Close Out Information
          </div>
          <dl class="wide">
            <dt>Were there any incidents, injuries or near misses?</dt>
            <dd>
              {{ yesNo(record.incidents) }}
              <span v-if="record.incidents === true">
                --
                {{ record.incidentsExplained }}
              </span>
            </dd>

            <dt>Are there hazards remaining?</dt>
            <dd>
              {{ yesNo(record.hazardsRemaining) }}
              <span v-if="record.hazardsRemaining === true">
                --
                {{ record.hazardsRemainingExplained }}
              </span>
            </dd>

            <dt>All permits closed out?</dt>
            <dd>{{ yesNo(record.allPermitsClosedOut) }}</dd>

            <dt>Work area cleaned end of shift?</dt>
            <dd>{{ yesNo(record.workAreaCleaned) }}</dd>
          </dl>

          <v-divider />
          <div class="font-weight-light my-2">
            Hazards Identified
          </div>
          <HazardsChosenList :recordId="recordId" />

          <v-divider />

          <div class="font-weight-light my-2">
            Task/Hazard Assessment Follow Up/Review
          </div>
          <dl>
            <dt>First Break</dt>
            <dd>{{ (record.reviewFollowup || {}).first_break }}</dd>

            <dt>Lunch Break</dt>
            <dd>{{ (record.reviewFollowup || {}).lunch_break }}</dd>

            <dt>Third Break</dt>
            <dd>{{ (record.reviewFollowup || {}).third_break }}</dd>
          </dl>
          <v-divider />

          <div class="font-weight-light my-2">
            Task Step Summary
          </div>
          <TaskStepSummary :taskSteps="taskSteps" />

          <div id="thirdparties" class="mt-2">
            <div class="font-weight-thin">
              {{ thirdparties.length == 0 ? "No " : "" }}Third Party Signatures
            </div>
            <template v-for="signon in thirdparties">
              <div :key="signon.id">
                <v-img :src="signon.url" crossorigin="anonymous" />
                <div class="text-xs-right">{{ signon.name }}</div>
                <v-divider />
              </div>
            </template>
          </div>

          <div id="attachments">
            <div class="font-weight-thin mt-2">
              {{ (attachments || []).length == 0 ? "No " : "" }}Attachments
            </div>
            <v-container fluid :grid-list-md="true">
              <v-layout row wrap>
                <v-flex
                  v-for="attachment in attachments"
                  :key="attachment.id"
                  xs4
                >
                  <v-card tile text>
                    <FileThumb :attachment="attachment" />
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>

    <NotFound v-else />
  </v-layout>
</template>

<script>
import FileThumb from "@/components/FileThumb";
import HazardsChosenList from "../components/HazardsChosenList";
import TaskStepSummary from "@/components/TaskStepSummary";
import { siteName } from "@/store/sites";
import { mapActions } from "vuex";
import {
  signons,
  attachments,
  taskSteps,
  workers,
  thirdparties,
  workerNames
} from "@/mixins/forms.js";
import NotFound from "@/components/NotFound";

export default {
  components: { FileThumb, HazardsChosenList, TaskStepSummary, NotFound },
  props: ["recordId"],
  computed: {
    forRecord() {
      return { type: "flha", id: this.recordId };
    },
    record() {
      return this.$store.getters["flhas/find"](this.recordId);
    },
    meta() {
      return this.$store.getters["flhas/meta"](this.recordId);
    },
    signons,
    attachments,
    taskSteps,
    workers,
    thirdparties,
    workerNames
  },
  methods: {
    yesNo(value) {
      return value === true ? "Yes" : "No";
    },
    ...mapActions("flhas", ["fetch"]),
    siteName
  },
  created() {
    if (this.meta && !this.meta.draft) this.fetch(this.recordId);
  }
};
</script>

<style></style>
