var code = "boom"; // case sensitive
var codeIndex = 0;

export default function(vm) {
  if (process.env.NODE_ENV != "development") {
    return;
  }

  document.addEventListener("keypress", function(e) {
    if (e.keyCode != code.charCodeAt(codeIndex++)) {
      codeIndex = 0;
      return;
    }
    if (codeIndex == code.length) {
      codeIndex = 0;
      vm.$store.resetAllState(["session"]);
    }
  });
}
