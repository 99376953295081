import extendBaseStore from "./libs/base";
import { forRecord } from "@/store/libs/utils";
import persist from "./signons_persist";

function forRecordFilter(record, type, id) {
  return record.signonableType === type && record.signonableId == id;
}

export default extendBaseStore({
  actions: {
    persist
  },
  getters: {
    forRecord: forRecord(forRecordFilter)
  }
});
