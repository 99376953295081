<template>
  <div class="part" data-cy="part" v-if="!part.loading">
    <div class="subtitle-1 mt-2">
      {{ part.title }}
    </div>
    <div>
      {{ part.description }}
    </div>

    <v-img v-if="part.imageUrl" :src="part.imageUrl" contain />

    <v-card flat v-for="line in lines" :key="line.id" two-line>
      <v-card-text class="py-2">
        <LineStatusIcon
          :text="{ goodText, badText }"
          :status="(inspectionData[line.id] || {}).line"
        />
        <span class="subtitle-2"> {{ line.text }} </span>
        <div class="font-italic">
          {{ comment(line) }}
        </div>
      </v-card-text>
    </v-card>
    <v-divider />
  </div>
</template>

<script>
import LineStatusIcon from "./LineStatusIcon";

export default {
  components: { LineStatusIcon },
  props: {
    part: {
      type: Object,
      required: true,
      default: () => ({ loading: true })
    },
    lines: {
      type: Array,
      required: true,
      default: () => []
    },
    goodText: {
      type: String,
      required: true,
      default: ""
    },
    badText: {
      type: String,
      required: true,
      default: ""
    },
    inspectionData: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    comment(line) {
      return (this.inspectionData[line.id] || {}).comments;
    }
  }
};
</script>
