import store from "@/store";
import { manifestPayload } from "@/store/libs/persistence";

const ROOT = { root: true };

export class Safetymeeting {
  constructor(id) {
    this.namespace = "safetymeetings";
    this.store = store;
    this.id = id;
  }

  get record() {
    return this.store.getters[`${this.namespace}/find`](this.id);
  }

  get meta() {
    return this.store.state[this.namespace].meta[this.id] || {};
  }

  get forRecord() {
    return {
      id: this.id,
      type: "safetymeeting"
    };
  }

  get signons() {
    return this.store.getters["signons/forRecord"](this.forRecord);
  }

  get form_attachments() {
    return this.store.getters["form_attachments/forRecord"](this.forRecord);
  }

  get items() {
    return this.store.getters["safetymeeting_items/forRecord"]({
      id: this.id,
      type: "safetymeeting"
    });
  }

  reportData() {
    return {
      safetymeeting: {
        location: this.record.location,
        site_id: this.record.siteId,
        meeting_type: this.record.meetingType,
        multiple_ca: this.record.multipleCa,
        locked_at: this.record.lockedAt,
        user_created_at: this.record.createdAt,
        user_updated_at: this.record.updatedAt,
        items_attributes: this.items.map(rec => ({
          title: rec.title,
          description: rec.description,
          item_type: rec.itemType
        })),
        pwa: manifestPayload(this.record, {
          signons: this.signons,
          attachments: this.form_attachments
        })
      },
      required_action_id: this.meta.reminderId
    };
  }

  updateSafetymeetingItems(included) {
    let items = (included || []).filter(
      item => item.type === "safetymeetingItem"
    );

    // delete existing, temporary records
    this.items.forEach(record =>
      this.store.dispatch("safetymeeting_items/destroy", record.id, ROOT)
    );

    // insert the new, persisted records
    this.store.dispatch("safetymeeting_items/insert", items, ROOT);
  }

  updateRelatedIDs(newId) {
    [
      { store: "form_attachments", prop: "reportId" },
      { store: "signons", prop: "signonableId" }
    ].forEach(({ store, prop }) => {
      this[store].forEach(record => {
        let attributes = { [prop]: newId };
        this.store.dispatch(
          `${store}/update`,
          { id: record.id, attributes },
          ROOT
        );
      });
    });
  }

  queueRelated() {
    ["signons", "form_attachments"].forEach(relatedType => {
      this[relatedType].forEach(record =>
        this.store.dispatch(
          "autosync/enqueue",
          {
            store: relatedType,
            action: "persist",
            id: record.id,
            tries: []
          },
          ROOT
        )
      );
    });
  }
}
