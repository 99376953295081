<template>
  <div>
    <app-toolbar title="Inspections" extended>
      <template v-slot:extension>
        <v-select
          :items="inspectionTypesOptions"
          :value="inspectionType"
          @change="value => $router.push(`/inspections/list/${value}`)"
          class="mr-2"
        >
        </v-select>
        <v-spacer />
        <v-btn
          fab
          small
          dark
          color="error"
          elevation="0"
          @click="createNew"
          data-cy="newInspection"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </template>
    </app-toolbar>

    <EmptyList v-if="utils.isEmpty(inspections)" />

    <v-list two-line class="py-0 borders-between">
      <v-list-item
        v-for="item in inspections"
        :key="item._id"
        :class="{ draft: isDraft(item._id) }"
        data-cy="inspectionItem"
      >
        <v-list-item-content @click="handleClick(item._id)">
          <v-list-item-title>
            <div class="subheading">
              {{ getTitle(item) }}:
              <span class="text-no-wrap grey--text text-darken-4">{{
                item.unitNumber
              }}</span>
            </div>

            <div class="text-no-wrap grey--text text-darken-4">
              {{ siteName(item.siteId) }}
              <span v-if="item.location"> &mdash; {{ item.location }}</span>
            </div>
          </v-list-item-title>
          <v-list-item-subtitle class="red--text text--lighten-3">{{
            item.createdAt | date
          }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar>
          <ActionsMenu
            v-if="isDraft(item._id)"
            :recordId="item._id"
            module="inspections"
          />
          <v-icon v-else color="grey lighten-2">mdi-lock</v-icon>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import ActionsMenu from "@/components/ActionsMenu";
import EmptyList from "@/components/EmptyList";
import { mapState, mapActions } from "vuex";
import { siteName } from "@/store/sites";
import { sortCreatedAt } from "@/store/libs/utils";
import { inspectionTypes } from "@/domains/inspections/lib";

export default {
  components: { EmptyList, ActionsMenu },
  props: {
    inspectionType: {
      default: "inspection"
    }
  },
  computed: {
    ...mapState("inspections", ["meta", "records"]),
    inspections() {
      return Object.values(this.records)
        .filter(rec => rec.type === this.inspectionType)
        .sort(sortCreatedAt)
        .reverse();
    },
    inspectionTypesOptions() {
      return Object.values(inspectionTypes).map(type => ({
        value: type.id,
        text: type.name
      }));
    }
  },

  methods: {
    ...mapActions("inspections", ["create", "fetchRecent"]),
    createNew() {
      this.create(this.inspectionType).then(record => {
        this.$router.push(`/inspections/edit/${record._id}`);
      });
    },
    isDraft(id) {
      return (this.meta[id] || {}).draft;
    },
    handleClick(id) {
      if (!this.isDraft(id)) {
        this.$router.push(`/inspections/${id}`);
      } else {
        this.$router.push(`/inspections/edit/${id}`);
      }
    },
    getForm(inspection) {
      return (
        this.$store.state.customForms.records[inspection.customFormId] || {}
      );
    },
    getTitle(inspection) {
      return inspection.title
        ? inspection.title
        : this.getForm(inspection).title;
    },
    siteName
  },
  created() {
    this.fetchRecent();
  }
};
</script>
