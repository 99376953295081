<template>
  <input
    type="file"
    ref="fileupload"
    @change="handleFileChange"
    :multiple="multiple"
    name="files"
  />
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openFileDialog() {
      this.$refs.fileupload.dispatchEvent(new MouseEvent("click"));
    },
    handleFileChange(e) {
      this.$emit("update", e.target.files);
      this.$refs.fileupload.value = null;
    }
  },
  mounted() {
    this.$on("open", this.openFileDialog);
  }
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
</style>
