//import seLog from "../common/seLog";
import extendBaseStore from "./libs/base";

export function newQ() {
  return {
    store: null,
    uuid: null,
    createdAt: new Date().toISOString(),
    onSuccess: null,
    onFailure: null,
    request: null,
    triedAt: []
  };
}

export default extendBaseStore({
  namespaced: true,
  state: {
    queue: [],
    lastSync: null,
    onLine: navigator.onLine
  },
  getters: {
    readyToSync: state => {
      return state.onLine ? state.queue.length : -1;
    },
    queueLength: state => {
      return state.queue.length;
    },
    tryCount: state => id => {
      return state.queue.find(queueItem => queueItem.id === id).tries.length;
    }
  },
  mutations: {
    enqueue(state, data) {
      state.queue.push({ ...data, ...{ tries: [] } });
    },
    dequeue(state, id) {
      let idx = state.queue.findIndex(item => item.id == id);
      state.queue.splice(idx, 1);
    },
    tried(state, { id, result }) {
      let idx = state.queue.find(item => item.id == id);
      idx.tries.push({
        dt: new Date().toISOString(),
        result: result
      });
    },
    setOnline(state, connected) {
      state.onLine = connected;
    }
  },
  actions: {
    enqueue({ commit }, data) {
      commit("enqueue", data);
    },
    dequeue({ commit }, id) {
      commit("dequeue", id);
    },
    tried({ commit }, { id, result }) {
      commit("tried", { id, result });
    },
    setupOnlineStatusEvents({ commit }) {
      window.addEventListener("online", function() {
        commit("setOnline", true);
      });
      window.addEventListener("offline", function() {
        commit("setOnline", false);
      });
      commit("setOnline", navigator.onLine);
    }
  }
});
