//import seLog from "@/common/seLog";
import Vue from "vue";
import $xhr from "@/xhr";
import get from "lodash/get";
import uuidv4 from "uuid/v4";
import extendBaseStore from "./libs/base";
import { notifyRequestError } from "@/bugsnag";

const root = { root: "true" };

const typeIsComment = rec => rec.type === "comment";

const insertRelatedComments = (response, commit) => {
  get(response, "data.included", [])
    .filter(typeIsComment)
    .forEach(record => {
      commit("comments/insert", record.attributes, root);
    });
};

const insertLocalComment = (commit, id, localComment) => {
  commit("comments/insert", localComment, root);
  commit("incrementCommentsCount", id);
};

export default extendBaseStore({
  state: {
    drafts: {}
  },
  mutations: {
    setDraft(state, { id, draft }) {
      Vue.set(state.drafts, id, draft);
    },
    deleteDraft(state, { id }) {
      Vue.delete(state.drafts, id);
    },
    incrementCommentsCount(state, id) {
      state.records[id].commentsCount++;
    }
  },
  actions: {
    // Handles inserting new records, but first
    // it merges in any stored draft properties.
    //
    // Typically this is used to insert new data
    // fetched from the back-end while maintaing
    // and applying local draft state.
    async insertWithDraftMerge({ commit, state, dispatch }, record) {
      const draft = state.drafts[record.id] || {};
      await dispatch("removeLocalComments", record.id);
      commit("insert", { ...record, ...draft });
    },

    // Inserts a new draft for a record, then
    // updates the related record with those
    // draft properties.
    setDraft({ commit }, { id, draft }) {
      commit("setDraft", { id, draft });
      commit("update", { id, attributes: draft });
    },

    removeLocalComments({ commit, rootGetters }, id) {
      if (
        rootGetters["autosync/records"].filter(item => {
          item.store == "required_actions" && item.id == id;
        }).length > 0
      ) {
        return;
      }
      rootGetters["comments/records"].forEach(comment => {
        if (
          comment.commentableId == id &&
          comment.commentableType === "required_action" &&
          comment.localCopy
        ) {
          commit("comments/destroy", comment.id, root);
        }
      });
    },

    async fetchAll({ dispatch }, status = "open") {
      const response = await $xhr("/required_actions", {
        params: { status }
      });

      if (!response.data) return;

      get(response, "data.data", []).forEach(record =>
        dispatch("insertWithDraftMerge", record.attributes)
      );
    },
    async fetchOne({ commit, dispatch }, id) {
      let response = await $xhr(`/required_actions/${id}`);
      const record = get(response, "data.data", false);
      if (!record) return;

      // insert/update required_action
      dispatch("insertWithDraftMerge", record.attributes);
      insertRelatedComments(response, commit);
    },

    // called by UI to mark a Reminder as missed
    missReminder({ state, dispatch, commit }, { id }) {
      let employee = this.state.session.employee;
      let commentRecord = {
        id: uuidv4(),
        body: state.drafts[id].comment.body,
        employeeId: employee.id,
        employeeName: `${employee.first_name} ${employee.last_name}`,
        commentableType: "required_action",
        commentableId: id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        localCopy: true
      };
      insertLocalComment(commit, id, commentRecord);
      dispatch(
        "autosync/enqueue",
        {
          store: "required_actions",
          action: "miss",
          id: id,
          comment: state.drafts[id].comment.body
        },
        root
      );
    },

    // called by autosync handler when processing a mark-reminder-as-missed entry
    async miss(context, id) {
      const record = context.getters["find"](id);
      if (!record) return Error("Not Found");

      const url = `/required_actions/${record.id}/miss`;
      const result = await $xhr.post(url, {
        comment: record.comment.body
      });

      if (!(result instanceof Error)) {
        context.commit("deleteDraft", { id });
        const attributes = result.data.data.attributes;
        context.dispatch("update", { id, attributes });
      } else {
        if (result.networkError) throw "Network Error";

        const response = result.response;
        const errors = get(response, "data.data.errors", [response.statusText]);
        const problem = { type: "missed_reminder", typeId: id, errors };
        context.dispatch("problems/new", problem, { root: true });
        notifyRequestError(result);
      }
    }
  },
  getters: {
    forReport: state => (type, id) => {
      return Object.values(state.records).find(record => {
        return record.raLinkType === type && record.raLinkId === id;
      });
    }
  }
});
