import bugsnag from "@bugsnag/js";

export const beforeSendCallback = report => {
  let vuex = JSON.parse(localStorage.getItem("vuex"));

  report.user = vuex.session.employee;
  report.updateMetaData("vuex", vuex);
  report.app.version = `${process.env.VUE_APP_VERSION}.${process.env.VUE_APP_BUILD}`;
};

var bugsnagClient;
if (process.env.VUE_APP_BUGSNAG_API_KEY) {
  bugsnagClient = bugsnag({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    beforeSend: beforeSendCallback
  });
}

export function bugsnagMetaFromError({ request, config }) {
  let { status, statusText } = request;
  let { method, data, url } = config;

  return {
    metaData: {
      status,
      statusText,
      method,
      data,
      url
    }
  };
}

export function notifyRequestError(error) {
  if (bugsnagClient) {
    bugsnagClient.notify(error, bugsnagMetaFromError(error));
  } else {
    console.log(error);
  }
}

export default bugsnagClient;
