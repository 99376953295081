<template>
  <v-card color="lighten-2" dark class="my-2">
    <v-card-text>
      <div class="mb-2" v-if="record">
        Flha
        <span class="mt-1 font-weight-thin">
          &mdash; {{ record.createdAt | date }} </span
        ><br />
        {{ site.location }}: {{ record.location }}
      </div>
      <div v-else>
        There was a problem syncinging an FLHA on this device, but the FLHA has
        since been deleted.
      </div>
      <span class="font-weight-thin">Errors:</span>
      <div v-for="(error, index) in problem.errors" :key="index">
        {{ error }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="red" @click="remove">Dismiss</v-btn>
      <v-btn :to="editPath" small color="primary" v-if="record">Fix</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["problem"],
  computed: {
    editPath() {
      return `/flhas/edit/${this.problem.typeId}`;
    },
    record() {
      return this.$store.state.flhas.records[this.problem.typeId];
    },
    site() {
      return this.$store.state.sites.records[this.record.siteId] || {};
    }
  },
  methods: {
    remove() {
      this.$store.dispatch("problems/destroy", this.problem.id);
    }
  }
};
</script>
