<template>
  <v-card height="100%">
    <v-card-title class="pb-0">
      <div class="sig-wrap">
        <canvas ref="canvas" :width="width()" :height="height()"></canvas>
        <div class="sigline">
          <v-btn text icon small @click="clearSignature" class="clearsig">
            <v-icon :color="validSignature ? 'black' : 'red'">mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-title>

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form">
        <v-combobox
          v-model="employee"
          :items="employees"
          label="Employee Name"
          clearable
          class="ma-1"
          :rules="employeeRules"
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="py-0">
      <v-spacer />
      <v-btn text color="blue" @click="done" :disabled="!allValid">Done</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SignaturePad from "signature_pad";

export default {
  props: {
    signon: Object
  },
  data() {
    return {
      valid: false,
      employee: this.setEmployeeFromSignon(),
      employeeRules: [v => !!v || "Name or Employee is required"],
      signaturePad: null
    };
  },
  computed: {
    employees() {
      return Object.values(this.$store.state.employees.records).map(e => ({
        value: e.id,
        text: `${e.firstName} ${e.lastName}`
      }));
    },
    allValid() {
      return this.valid && this.validSignature;
    },
    validSignature() {
      return this.signaturePad && !this.signaturePad.isEmpty();
    }
  },
  methods: {
    setEmployeeFromSignon() {
      if (!this.signon.name) return undefined;
      return {
        text: this.signon.name,
        value: this.signon.employeeId
      };
    },
    width() {
      return this.$root.$el.clientWidth - 32; // 32px padding
    },
    height() {
      return this.width() * 0.25;
    },
    // copied from signature_pad source, method of same name.
    // modified to maintain aspect ratio of 4:1 width:height
    resizeCanvas() {
      const canvas = this.$refs.canvas;
      const data = this.signaturePad.toDataURL();
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = this.width() * ratio;
      canvas.height = this.height() * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      this.signaturePad.clear();
      this.signaturePad.fromDataURL(data);
    },
    done() {
      this.$emit("done", {
        name: this.employee.text || this.employee,
        employeeId: this.employee.value,
        url: this.signaturePad.toDataURL()
      });
    },
    clearSignature() {
      this.signaturePad.clear();
    },
    resetAll() {
      this.clearSignature();
      this.$refs.form.reset();
    },
    fromCrossOriginImage(imageUrl) {
      const image = new Image();
      image.crossOrigin = "anonymous";
      this.signaturePad._reset();

      image.onload = () => {
        const ctx = this.signaturePad._ctx;
        ctx.drawImage(image, 0, 0, this.width(), this.height());
      };

      image.src = imageUrl;
      this.signaturePad._isEmpty = false;
    }
  },
  mounted() {
    this.signaturePad = new SignaturePad(this.$refs.canvas);
    this.onResizeHandler = this.resizeCanvas.bind(this);
    window.addEventListener("resize", this.onResizeHandler, false);
    this.resizeCanvas();
    this.fromCrossOriginImage(this.signon.url);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeHandler, false);
  }
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
}

.sigline {
  position: relative;
  top: -20px;
  border-top: 1px dashed #888;
  width: 100%;
  margin: 0 auto;
}

.clearsig {
  position: absolute;
  top: -35px;
  right: -8px;
}
</style>
