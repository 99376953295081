<template>
  <v-card flat class="pa-2">
    <div class="blue-grey--text px-2 pt-2">
      <span class="text-capitalize"
        >{{ type }} Items

        <span v-if="type === 'action'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-help-circle
              </v-icon>
            </template>
            <span>Action items trigger a corrective action.</span>
          </v-tooltip>
        </span>
      </span>
    </div>
    <v-list three-line dense class="pa-0">
      <template v-for="item in items">
        <v-list-item :key="item.id" @click="$emit('edit-item', item)">
          <v-list-item-action>
            <v-btn icon @click.stop="() => deleteItem(item.id)">
              <v-icon color="red lighten-2">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="body-2">{{
              item.title
            }}</v-list-item-title>
            <v-list-item-subtitle class="body-2 font-weight-light">{{
              item.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="`-${item.id}-`" />
      </template>
    </v-list>
    <v-btn @click="$emit('addItem', type)" small outlined color="primary" block
      >Add {{ type }}</v-btn
    >
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: ["items", "type"],
  computed: {},
  methods: {
    ...mapMutations("safetymeeting_items", {
      deleteItem: "destroy"
    })
  }
};
</script>
