//import seLog from "./common/seLog";
import Vue from "vue";
import * as autosync from "./plugins/autosync.js";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import store from "./store";
import DateFilter from "./common/date_filter";
import bugsnagClient from "./bugsnag";
import bugsnagVue from "@bugsnag/plugin-vue";
import Toolbar from "@/components/Toolbar";
import setupResetVuex from "./resetVuex";
import vuetify from "./plugins/vuetify";
import isEmpty from "lodash/isEmpty";

// add
Vue.prototype.utils = { isEmpty: value => isEmpty(value) };

// init bugsnag
if (bugsnagClient) bugsnagClient.use(bugsnagVue, Vue);

// set app version
Vue.prototype.$version = `${process.env.VUE_APP_VERSION}.${process.env.VUE_APP_BUILD}`;

// add date filter
Vue.filter("date", DateFilter);

// add $xhr to store and Vue
Vue.prototype.$xhr = store.$xhr;

// set devmode
Vue.prototype.$devMode = process.env.NODE_ENV !== "production";

// Vue config options
Vue.config.productionTip = false;
Vue.config.performance = Vue.prototype.$devMode;

// add global component app-toolbar
Vue.component("app-toolbar", Toolbar);

// mount App
let vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

window.app = vm;

// init Autosync
autosync.setup(vm);

// Setup our secret code to reset Vuex
setupResetVuex(vm);
