<template>
  <VCombobox
    :value="value"
    @input="handleChange"
    :items="locations"
    item-text="location"
    item-value="location"
    :return-object="false"
    label="Choose Location"
    clearable
    data-cy="location"
    :rules="rules"
  />
</template>

<script>
export default {
  data() {
    return {
      rules: [
        value => this.allowedLocation(value) || "New locations diallowed."
      ]
    };
  },
  props: {
    value: {
      Type: String
    },
    locations: {
      type: Array
    }
  },
  computed: {
    settings() {
      return this.$store.state.locations.settings;
    }
  },
  methods: {
    handleChange(value) {
      const newValue = Array.isArray(value) ? value[0] : value;
      this.$emit("input", newValue);
    },
    allowedLocation(value) {
      if (!this.settings.restrict_locations) return true;
      if (value === null) return true;

      return this.locations.some(x => x.location === value);
    }
  }
};
</script>
