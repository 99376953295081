import extendBaseStore from "@/store/libs/base";
import { includedOfType, imageCacheAdd } from "@/store/libs/utils";
import Vue from "vue";
import $xhr from "@/xhr";
import get from "lodash/get";

export default extendBaseStore({
  state: {
    parts: {},
    lines: {}
  },
  actions: {
    async fetchAll({ commit }) {
      const { data } = await $xhr("/custom_forms");
      if (!data) return;

      const records = get(data, "data", []).map(r => r.attributes);
      const parts = includedOfType("part", data.included);
      const lines = includedOfType("line", data.included);

      commit("insert", records);
      commit("insertParts", parts);
      commit("insertLines", lines);
    },
    insertIncludedCustomForm({ commit }, includedData) {
      const customForms = includedOfType("customForm", includedData);
      const parts = includedOfType("part", includedData);
      const lines = includedOfType("line", includedData);

      commit("insert", customForms);
      commit("insertParts", parts);
      commit("insertLines", lines);
    }
  },
  mutations: {
    insertParts(state, parts) {
      const imageUrls = [];
      for (const part of parts) {
        Vue.set(state.parts, part.id, part);
        if (part.imageUrl) imageUrls.push(part.imageUrl);
      }
      imageCacheAdd(imageUrls);
    },
    insertLines(state, lines) {
      for (const line of lines) {
        Vue.set(state.lines, line.id, line);
      }
    }
  },
  getters: {
    getParts: state => customFormId =>
      Object.values(state.parts)
        .filter(part => part.customFormId === customFormId)
        .sort((a, b) => a.order - b.order),
    getLines: state => partId =>
      Object.values(state.lines)
        .filter(line => line.partId === partId)
        .sort((a, b) => a.order - b.order),
    formDataTemplate: (state, getters) => customFormId => {
      const formData = {};
      for (const part of getters.getParts(customFormId)) {
        for (const line of getters.getLines(part.id)) {
          formData[line.id] = { line: null, comments: null };
        }
      }
      return formData;
    }
  }
});
