<template>
  <v-tab-item>
    <EmptyList v-if="utils.isEmpty(requiredActions)" />
    <v-list two-line class="borders-between py-0">
      <v-list-item
        v-for="ra in requiredActions"
        :key="ra.id"
        @click="raShowDetails(ra.id)"
      >
        <v-list-item-content>
          <v-list-item-title class="text-capitalize">
            {{ ra.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span class="">{{ ra.raType }}</span> &mdash;
            <span class="">{{ ra.siteName }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span class="red--text text--lighten-3">
              {{ ra.dueDate | date }}</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-badge overlap color="grey">
            <template v-slot:badge v-if="ra.commentsCount > 0">
              {{ ra.commentsCount }}
            </template>
            <v-icon large color="grey lighten-3">
              mdi-comment
            </v-icon>
          </v-badge>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-tab-item>
</template>

<script>
import { mapGetters } from "vuex";
import { sortByDate } from "@/store/libs/utils";
import EmptyList from "@/components/EmptyList";

export default {
  components: { EmptyList },
  watch: {
    $route: "fetchRequiredActions"
  },
  methods: {
    raShowDetails(id) {
      this.$router.push({
        name: "reminders_show",
        params: { id }
      });
    },
    fetchRequiredActions() {
      this.$store.dispatch(
        "required_actions/fetchAll",
        this.$route.params.status
      );
    }
  },
  computed: {
    ...mapGetters("required_actions", ["records"]),
    requiredActions() {
      return this.records
        .filter(ra => ra.status === this.$route.params.status)
        .sort(sortByDate("dueDate"))
        .reverse();
    }
  },
  mounted() {
    this.fetchRequiredActions();
  }
};
</script>
