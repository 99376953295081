import Axios from "axios";
import { authToken } from "@/store/libs/utils";
import emitter from "@/events";

const AXIOS_BASE_URL =
  (process.env.VUE_APP_API_HOST || "") +
  (process.env.VUE_APP_API_BASE_URL || "");

const seAxios = (options = {}) => {
  options = Object.assign(
    {},
    {
      baseURL: AXIOS_BASE_URL,
      timeout: 10000
    },
    options
  );

  let axios = Axios.create(options);

  axios.interceptors.request.use(
    config => {
      config.headers.common["Authorization"] = authToken();
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      if (response.headers.authorization) {
        emitter.emit("authTokenRefresh", response.headers.authorization);
      }
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        window.location.href = "/#/signin";
      }
      return Promise.reject(error);
    }
  );

  return axios;
};

/*
  Network Errors should not raise an exception.

  Instead a response object with property
  `networkError` will be returned.

  // 401's are caught in the custom axios.
*/
function xhr($axios) {
  const $xhr = async (url, options) => {
    emitter.emit("xhrStart");
    try {
      return await $axios(url, options);
    } catch (error) {
      if (!error.response) {
        error.networkError = true;
        return error;
      } else {
        // all other errors should raise an exception
        return error;
      }
    } finally {
      emitter.emit("xhrStop");
    }
  };

  ["post", "put", "patch"].forEach(
    method => ($xhr[method] = (url, data) => $xhr(url, { method, data }))
  );
  $xhr.delete = url => $xhr(url, { method: "delete" });

  return $xhr;
}

const $xhr = xhr(seAxios());

export { $xhr as default };
export { xhr, seAxios };
