export const inspectionTypes = {
  inspection: {
    id: "inspection",
    name: "Vehicle & Equipment",
    formTypes: ["vehicle_inspection", "equipment_inspection"]
  },
  quality_control_inspection: {
    id: "quality_control_inspection",
    name: "Quality Control",
    formTypes: ["quality_control_inspection"]
  },
  site_inspection: {
    id: "site_inspection",
    name: "Site",
    formTypes: ["site_inspection"]
  }
};

export function calcCompositeId(record) {
  const id = record.id;
  const type = record.type;

  if (!id || !type) throw "Can not calcCompositeId without id and type.";

  return `${type}.${id}`;
}
