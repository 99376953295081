<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn small icon v-on="on" data-cy="actionMenuButton">
        <v-icon color="grey">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list data-cy="actionMenuActionList">
      <v-list-item
        v-if="actionOptions.canView"
        :to="`/${this.module}/${recordId}`"
      >
        <v-list-item-action><v-icon>fa-eye</v-icon></v-list-item-action>
        <v-list-item-title>View</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="actionOptions.canEdit"
        :to="`/${this.module}/edit/${recordId}`"
      >
        <v-list-item-action>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-action>
        <v-list-item-title>Edit</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="actionOptions.canDelete" @click="deleteDraft">
        <v-list-item-action>
          <v-icon color="warning">mdi-delete</v-icon>
        </v-list-item-action>
        <v-list-item-title>Delete</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ["module", "recordId"],
  computed: {
    actionOptions() {
      return {
        canEdit: this.isDraft,
        canDelete: this.isDraft,
        canView: true
      };
    },
    editNav() {
      return {
        path: "/edit",
        params: { id: this.recordId }
      };
    },
    item() {
      return this.$store.state[this.module].records[this.recordId];
    },
    isDraft() {
      return (this.$store.state[this.module].meta[this.recordId] || {}).draft;
    }
  },
  methods: {
    deleteDraft() {
      this.$store.dispatch(`${this.module}/removeRecord`, this.recordId);
    }
  }
};
</script>
