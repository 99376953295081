export default {
  actions: {
    fetchOnLoad({ dispatch }) {
      dispatch("sites/fetchAll");
      dispatch("locations/fetchAll");
      dispatch("employees/fetchAll");
      dispatch("safetymeetings/fetchMeetingTypes");
      dispatch("flhas/fetchHazardOptions");
      dispatch("customForms/fetchAll");
      dispatch("assetTypes/fetchAll");
      dispatch("inspections/fetchUnitNumbers");
    }
  }
};
