<template>
  <v-layout class="reminders" fill-height>
    <app-toolbar :backButton="false" title="Reminders" extended>
      <template v-slot:extension>
        <v-tabs :value="activeTab" slider-color="pink" grow>
          <v-tab ripple to="/reminders/list/open">Open</v-tab>
          <v-tab ripple to="/reminders/list/completed">Completed</v-tab>
          <v-tab ripple to="/reminders/list/missed">Missed</v-tab>
        </v-tabs>
      </template>
    </app-toolbar>
    <v-flex>
      <v-tabs-items :value="activeTab">
        <RequiredActionTab />
      </v-tabs-items>
    </v-flex>
  </v-layout>
</template>

<script>
import RequiredActionTab from "@/domains/reminders/components/RequiredActionTab";

export default {
  components: { RequiredActionTab },
  data() {
    return {
      activeTab: 0
    };
  }
};
</script>
