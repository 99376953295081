<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    fullscreen
    scrollable
    persistent
  >
    <v-card height="100%">
      <app-toolbar :title="item.itemType" :app="false" :menuButton="false" />
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-textarea
            v-model="item.title"
            :rules="titleRules"
            label="Title"
            text
            color="blue"
            rows="1"
            auto-grow
            required
          ></v-textarea>
          <v-textarea
            v-model="item.description"
            label="Description"
            text
            auto-grow
            color="blue"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="dismiss">Cancel</v-btn>
        <v-btn text color="blue" :disabled="!this.valid" @click="handleDone"
          >Done</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: ["itemInfo"],
  data: () => ({
    value: true,
    valid: false,
    titleRules: [v => !!v || "Title is required"]
  }),
  computed: {
    ...mapGetters("safetymeeting_items", ["find"]),
    item() {
      const itemFromStore = this.find(this.itemInfo.id);
      if (itemFromStore) return { ...itemFromStore };
      return {
        title: "",
        description: "",
        ...this.itemInfo
      };
    }
  },
  methods: {
    ...mapMutations("safetymeeting_items", {
      updateItem: "update",
      insertItem: "insert"
    }),
    dismiss() {
      this.$refs.form.reset();
      this.$emit("dismiss");
    },
    handleDone() {
      this.insertItem(this.item);
      this.dismiss();
    },
    editItem(item) {
      this.itemDialog = { ...item };
    }
  }
};
</script>
