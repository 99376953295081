import Vue from "vue";
import $xhr from "@/xhr";
import get from "lodash/get";

export default {
  namespaced: true,
  state: {
    records: {},
    settings: {}
  },
  mutations: {
    insert(state, records) {
      Vue.set(state, "records", records);
    },
    setSettings(state, newSettings) {
      Vue.set(state, "settings", newSettings);
    }
  },
  actions: {
    async fetchAll({ commit, dispatch }) {
      const dataToRecords = (obj, rec) =>
        Object.assign(obj, { [rec.id]: rec.attributes });
      const { data } = await $xhr("/locations");
      const records = get(data, "data", []).reduce(dataToRecords, {});
      commit("insert", records);

      dispatch("fetchSettings");
    },
    async fetchSettings({ commit }) {
      const { data } = await $xhr("/locations/settings");
      if (typeof data === "object" && data !== null) {
        commit("setSettings", data.settings);
      } else {
        throw "fetchSettings: xhr did not return data object.";
      }
    }
  },
  getters: {}
};
