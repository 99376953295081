<template>
  <v-snackbar :value="true" v-model="display" color="red" timeout="-1">
    The application is low on storage.

    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="$store.commit('dismissLowStorage')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    storage() {
      return this.$store.state.storage;
    },
    display() {
      if (!this.storage.remaining || this.storage.dismissed) return false;

      return this.storage.remaining <= 0;
    }
  }
};
</script>
