import extendBaseStore from "./libs/base";
import { imageCacheAdd } from "@/store/libs/utils";
import get from "lodash/get";
import $xhr from "@/xhr";

export default extendBaseStore({
  actions: {
    async fetchAll({ commit }) {
      const response = await $xhr("/employees");
      const data = get(response, "data.data", []);
      if (!data) return;

      const records = data.map((n) => n.attributes);
      const profileImgUrls = records.map((rec) => rec.profileImageUrl);

      commit("insert", records);
      imageCacheAdd(profileImgUrls);
    },
  },
});
