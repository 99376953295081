import Dexie from "dexie";

const db = new Dexie("imageDb");
db.version(1).stores({
  attachments: `++id`
});

// data should be base64 encoded url
export async function putFile(data) {
  return await db.attachments.add({ url: data });
}

export async function getFile(id) {
  return await db.attachments.get(id);
}

export async function deleteFile(id) {
  return await db.attachments.delete(id);
}
