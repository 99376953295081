import $xhr from "@/xhr";
import { includedOfType } from "@/store/libs/utils";
import get from "lodash/get";
import Flha from "@/models/Flha";
import { notifyRequestError } from "@/bugsnag";
import {
  xhrPayload,
  taskStepPayload
} from "@/domains/flhas/store/libs/persistence";
import { removeDraftRA } from "@/store/libs/persistence";

import { updateForeignKeys, manifestPayload } from "@/store/libs/persistence";

const ROOT = { root: true };

export async function fetchHazardOptions({ commit }) {
  const { data } = await $xhr("/flhas/hazards");
  if (!data) return;

  commit("setHazardOptions", data);
}

export async function fetchRecent({ dispatch }) {
  const { data } = await $xhr("/flhas");
  if (!data) return;

  const records = get(data, "data", []).map(n => n.attributes);
  dispatch("insert", records);
}

export async function fetch({ commit }, id) {
  const { data } = await $xhr(`/flhas/${id}`);
  if (!data) return;

  const record = data.data.attributes;
  const included = data.included;

  commit("insert", record);

  const taskSteps = includedOfType("taskStep", included);
  const thirdpartySignons = includedOfType("thirdparty", included);
  const workerSignons = includedOfType("workerSignon", included);
  const attachments = includedOfType("formAttachment", included);

  commit("taskSteps/insert", taskSteps, ROOT);
  commit("signons/insert", thirdpartySignons, ROOT);
  commit("signons/insert", workerSignons, ROOT);
  commit("form_attachments/insert", attachments, ROOT);
}

export async function create({ dispatch }) {
  const record = Flha();
  await dispatch("insert", record);
  dispatch("setMeta", { id: record.id, meta: { draft: true } });
  return record;
}

export async function removeRecord({ dispatch }, id) {
  dispatch("destroyMeta", id);
  dispatch("removeRelated", id);
  dispatch("destroy", id);
}

export async function getRelated({ rootGetters }, { id, relations }) {
  let result = {};
  for (let store of relations) {
    result[store] = rootGetters[`${store}/forRecord`]({ id, type: "flha" });
  }
  return result;
}

export async function removeRelated({ dispatch, rootGetters }, id) {
  for (let store of ["signons", "form_attachments", "taskSteps"]) {
    const records = rootGetters[`${store}/forRecord`]({ id, type: "flha" });
    records.forEach(rec => dispatch(`${store}/destroy`, rec.id, ROOT));
  }
}

export async function closeout({ dispatch }, id) {
  const lockedAt = new Date().toISOString();
  dispatch("update", { id, attributes: { lockedAt } });
  dispatch("unsetMeta", { id, prop: "draft" });
  dispatch(
    "autosync/enqueue",
    {
      store: "flhas",
      action: "persist",
      id
    },
    ROOT
  );
}

export async function persist(context, id) {
  const type = "flha";
  const forRecord = { id, type };
  const { dispatch, getters, rootGetters } = context;

  const record = getters["find"](id);
  const meta = getters["meta"](id);
  if (!record) return Error("Not Found");

  const signons = rootGetters["signons/forRecord"](forRecord);
  const attachments = rootGetters["form_attachments/forRecord"](forRecord);
  const taskSteps = rootGetters["taskSteps/forRecord"](forRecord);

  const payload = xhrPayload(record);
  payload.flha.pwa = manifestPayload(record, { signons, attachments });

  // add taskSteps to payload
  payload.flha.task_steps_attributes = taskSteps.map(taskStepPayload);

  payload.required_action_id = meta.reminderId;

  // post to back-end
  const result = await $xhr.post("/flhas", payload);

  if (!(result instanceof Error)) {
    const { data } = result.data;
    const { included } = result.data;
    const newId = data.id;

    // insert persisted record
    dispatch("insert", data.attributes);

    // update assocated taskSteps
    const taskSteps = includedOfType("taskStep", included);
    dispatch("taskSteps/insert", taskSteps, ROOT);

    // update foreign keys for signons, form_attachments
    updateForeignKeys(signons, "signons", "signonableId", newId, dispatch);
    updateForeignKeys(
      attachments,
      "form_attachments",
      "reportId",
      newId,
      dispatch
    );

    //  destroy draft record
    dispatch("removeRecord", id);

    // clean up any related reminder draft data
    removeDraftRA("flha", id, context);

    // queue signons, form_attachments to be persisted
    const related = await dispatch("getRelated", {
      id: newId,
      relations: ["signons", "form_attachments"]
    });

    for (const store in related) {
      for (const record of related[store]) {
        dispatch(
          "autosync/enqueue",
          { store, action: "persist", id: record.id, tries: [] },
          ROOT
        );
      }
    }
  } else {
    if (result.networkError) throw "Network Error";

    const response = result.response;
    const errors = get(response, "data.errors", [response.statusText]);
    const problem = { type: "flha", typeId: id, errors };
    // insert problem record
    dispatch("problems/new", problem, ROOT);

    // revert to draft
    dispatch("setMeta", { id, meta: { draft: true } });

    // trigger bugsnag notification
    notifyRequestError(result);
  }
}
