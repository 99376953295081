<template>
  <div>
    <app-toolbar title="Safety Meetings" extended>
      <template v-slot:extension>
        <v-spacer></v-spacer>
        <v-btn fab small dark color="error" elevation="0" @click="createNew"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </template>
    </app-toolbar>
    <EmptyList v-if="utils.isEmpty(list)" />

    <v-list two-line class="py-0 borders-between" id="meetings-list">
      <v-list-item
        v-for="meeting in list"
        :key="meeting.id"
        :class="{ draft: meta(meeting.id).draft }"
      >
        <v-list-item-content @click="handleClick(meeting)">
          <v-list-item-title class="text-capitalize">
            {{ meeting.meetingType }}
            <template v-if="meeting.siteId">
              &mdash;
              <span class="font-weight-thin">{{
                siteName(meeting.siteId)
              }}</span>
            </template>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span class="red--text text--lighten-3">{{
              meeting.createdAt | date
            }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <ActionsMenu
            v-if="meta(meeting.id).draft"
            :recordId="meeting.id"
            module="safetymeetings"
          />
          <v-icon v-else color="grey lighten-2">mdi-lock</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import ActionsMenu from "@/components/ActionsMenu";
import EmptyList from "@/components/EmptyList";
import { sortCreatedAt } from "@/store/libs/utils";
import { siteName } from "@/store/sites";
import { mapActions } from "vuex";
export default {
  components: { ActionsMenu, EmptyList },
  computed: {
    list() {
      return Object.values(this.safetymeetings)
        .sort(sortCreatedAt)
        .reverse();
    },
    safetymeetings() {
      return this.$store.state.safetymeetings.records;
    }
  },
  methods: {
    ...mapActions("safetymeetings", ["insert", "fetchRecent", "setMeta"]),
    createNew() {
      this.$store.dispatch("safetymeetings/create").then(record => {
        this.$router.push(`/safetymeetings/edit/${record.id}`);
      });
    },
    meta(id) {
      return this.$store.getters["safetymeetings/meta"](id);
    },
    handleClick(meeting) {
      if (!this.meta(meeting.id).draft) {
        this.$router.push(`/safetymeetings/${meeting.id}`);
      } else {
        this.$router.push(`/safetymeetings/edit/${meeting.id}`);
      }
    },
    siteName
  },
  created() {
    this.fetchRecent();
  }
};
</script>
