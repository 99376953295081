<template>
  <v-dialog v-if="show" v-model="show">
    <v-card data-cy="discardDialog">
      <v-card-title class="title lighten-2" primary-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ body }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="handleDiscard">
          Discard
        </v-btn>
        <v-btn color="primary" text @click="handleContinue">
          Back
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show", "title", "body", "handleDiscard", "handleContinue"]
};
</script>
