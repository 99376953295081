<template>
  <v-layout fill-height>
    <app-toolbar title="Company Contacts" />
    <v-flex>
      <v-list subheader>
        <v-text-field
          placeholder="Search"
          append-icon="mdi-magnify"
          v-model="search"
          clearable
          class="mx-3 mt-0"
        ></v-text-field>
        <EmptyList v-if="utils.isEmpty(filteredContacts)" />
        <div v-for="contact in filteredContacts" :key="contact.id">
          <v-list-item>
            <v-list-item-avatar>
              <v-lazy-image
                :src="contact.profileImageUrl"
                :src-placeholder="placeholderImg"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ contact.firstName }}
                {{ contact.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ contact.employee_type }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <ContactMenu
              :phone="contact.phone"
              :phone2="contact.phone2"
              :email="contact.email"
            />
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list>
    </v-flex>
  </v-layout>
</template>

<script>
import placeholderImg from "@/assets/user-circle-solid.svg";
import VLazyImage from "v-lazy-image";
import ContactMenu from "@/components/contacts/ContactMenu";
import EmptyList from "@/components/EmptyList";

export default {
  data() {
    return {
      search: "",
      placeholderImg: placeholderImg
    };
  },
  components: { ContactMenu, VLazyImage, EmptyList },
  computed: {
    contacts() {
      return Object.values(this.$store.state.employees.records).sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
    },
    filteredContacts() {
      if (!this.search) return this.contacts;
      let search = this.search.toLowerCase();
      return this.contacts.filter(
        c =>
          c.lastName.toLowerCase().includes(search) ||
          c.firstName.toLowerCase().includes(search) ||
          c.employeeType.toLowerCase().includes(search)
      );
    }
  },
  methods: {
    getContacts() {
      this.$store.dispatch("employees/fetchAll");
    }
  },
  mounted() {
    this.getContacts();
  }
};
</script>

<style scoped>
.v-list {
  height: 100%;
}

.v-list__tile__sub-title {
  text-transform: capitalize;
}

.v-lazy-image {
  opacity: 0.3;
}

.v-lazy-image-loaded {
  opacity: 1;
}
</style>
