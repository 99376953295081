import { reportTypesMapSingularToPlural as reportTypes } from "./";

export default function({ problemTypePluralized, idField, typeField }) {
  return {
    computed: {
      problemRecord() {
        return this.$store.state[problemTypePluralized].records[
          this.problem.typeId
        ];
      },
      reportTypePluralized() {
        return reportTypes[this.problemRecord[typeField]];
      },
      reportRecordId() {
        if (this.reportTypePluralized === "inspections") {
          return `${this.problemRecord[typeField]}.${this.problemRecord[idField]}`;
        } else {
          return this.problemRecord[idField];
        }
      },
      reportRecord() {
        return this.$store.state[this.reportTypePluralized].records[
          this.reportRecordId
        ];
      },
      linkPath() {
        return `/${this.reportTypePluralized}/${this.reportRecordId}`;
      }
    },

    methods: {
      retry() {
        this.$store.dispatch("autosync/enqueue", {
          store: problemTypePluralized,
          action: "persist",
          id: this.problemRecord.id,
          tries: []
        });
        this.$store.dispatch("problems/destroy", this.problem.id);
      },
      fail() {
        this.$store.dispatch(
          `${problemTypePluralized}/destroy`,
          this.problemRecord.id
        );
        this.$store.dispatch("problems/destroy", this.problem.id);
      }
    }
  };
}
