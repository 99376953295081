const clone = state => JSON.parse(JSON.stringify(state));

export default function VuexReset() {
  return store => {
    const initialState = clone(store.state);

    store.registerModule("resetPlugin", {
      namespaced: true,
      mutations: { touch: () => {} }
    });

    store.resetAllState = (except = []) => {
      // copy the initialState
      const newState = clone(initialState);

      // copy over existing state
      except.forEach(key => (newState[key] = clone(store.state[key])));

      // set app state to newState
      // localStorage.vuex = JSON.stringify(newState);
      store.replaceState(newState);

      // "touch" vuex with mutation; to ensure
      // all entities (plugins, vue developer tools, etc)
      // are aware of the state change
      store.commit("resetPlugin/touch");
    };
  };
}
