<template>
  <v-dialog v-model="dialog" dark overlay-opacity="0.9">
    <v-card>
      <v-card-title class="red lighten-2">
        Reset Application Data
      </v-card-title>
      <v-card-text class="pa-6 body-2">
        <p>
          This action can be taken to address storage space issues or bugs.
        </p>

        <p>
          <strong
            >Caution: Clearing application data can result in data loss:</strong
          >
        </p>
        <p>
          Any inspections or hazard assessments that have not been
          <strong>closed out and synced to the server</strong> will be lost.
        </p>

        <p>
          Type <code>reset</code> into the input below, and press the button to
          confirm.
        </p>

        <v-text-field v-model="resetInput" />
        <v-btn
          color="red lighten-2"
          @click="performReset"
          :disabled="resetConfirmed"
          >Reset Application!</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import emitter from "@/events";

export default {
  data() {
    return {
      dialog: false,
      resetInput: ""
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.resetInput = "";
      }
    }
  },
  computed: {
    resetConfirmed() {
      return this.resetInput.toLowerCase() !== "reset";
    }
  },
  methods: {
    performReset() {
      this.$store.resetAllState(["session"]);
      this.dialog = false;
      window.location.reload();
    }
  },
  created() {
    emitter.on("showClearAllDialog", () => {
      this.dialog = true;
    });
  }
};
</script>
