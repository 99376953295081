<template>
  <div>
    <div class="grey--text text--darken-2">
      {{ label }}
    </div>
    <v-radio-group
      :value="value"
      @change="event => $emit('input', event)"
      :rules="rules"
      class="mt-1"
    >
      <v-radio label="Yes" :value="true"></v-radio>
      <v-radio label="No" :value="false"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: ["value", "label", "rules"]
};
</script>

<style></style>
