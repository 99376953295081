<template>
  <v-list-item @click="activate(item.action)">
    <v-list-item-action>
      <v-icon class="fa-fw">{{ item.icon }}</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title
        >{{ item.text }} <slot name="indicator"
      /></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    activate({ path, handler }) {
      // .catch(() => {}); avoids dealing with NavigationDuplicated uncaught promise error
      if (path) this.$router.push({ path }).catch(() => {});
      if (handler) handler();
    }
  }
};
</script>
