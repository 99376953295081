<template>
  <v-card class="pa-3" flat>
    <v-form v-model="valid">
      <div class="blue-grey--text">
        Meeting Type &amp; Location
      </div>
      <v-select
        v-model="meetingType"
        :items="meetingTypes"
        :rules="meetingTypeRules"
        label="Choose Meeting Type"
        required
      />
      <v-select
        v-model="siteId"
        :items="sites"
        item-text="location"
        item-value="id"
        :rules="siteRules"
        label="Choose Site"
        required
      />
      <LocationInput
        :value="location"
        @input="value => (location = value)"
        :locations="locations"
      />

      <v-switch
        v-model="multipleCa"
        :label="`Separate Action Items? ${multipleCa ? 'On' : 'Off'}`"
      ></v-switch>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import LocationInput from "@/components/LocationInput";

export default {
  components: { LocationInput },
  props: ["meetingId"],
  data: () => ({
    valid: false,
    meetingTypeRules: [v => !!v || "Meeting Type is required"],
    siteRules: [v => !!v || "Site is required"]
  }),
  computed: {
    ...mapState("safetymeetings", {
      record: function(state) {
        return state.records[this.meetingId];
      }
    }),
    meetingTypes() {
      return Object.values(this.$store.state.safetymeetings.meetingTypes);
    },
    sites() {
      return Object.values(this.$store.state.sites.records);
    },
    locations() {
      return Object.values(this.$store.state.locations.records);
    },
    meetingType: {
      get() {
        return this.record.meetingType;
      },
      set(value) {
        this.update({ meetingType: value });
      }
    },
    siteId: {
      get() {
        return this.record.siteId;
      },
      set(value) {
        this.update({ siteId: value });
      }
    },
    location: {
      get() {
        return this.record.location;
      },
      set(value) {
        this.update({ location: value });
      }
    },
    multipleCa: {
      get() {
        return this.record.multipleCa;
      },
      set(value) {
        this.update({ multipleCa: value });
      }
    }
  },
  methods: {
    update(attributes) {
      this.$store.dispatch("safetymeetings/update", {
        id: this.meetingId,
        attributes
      });
    }
  },
  watch: {
    valid(value) {
      this.$emit("valid", value);
    }
  }
};
</script>
