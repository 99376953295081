import RootView from "../views/Root";
import List from "../views/List";
import Edit from "../views/Edit";
import Show from "../views/Show";

export default {
  path: "/flhas",
  component: RootView,
  redirect: "/flhas/list",
  children: [
    {
      name: "flhas",
      path: "list",
      component: List
    },
    {
      name: "edit_flha",
      path: "edit/:recordId",
      component: Edit,
      props: true
    },
    {
      name: "show_flha",
      path: ":recordId",
      component: Show,
      props: true
    }
  ]
};
