import Inspection from "../models/Inspection";
import { includedOfType } from "@/store/libs/utils";
import $xhr from "@/xhr";
import get from "lodash/get";

const ROOT = { root: true };

export async function create({ dispatch }, type) {
  const record = Inspection(type);
  await dispatch("insert", record);
  dispatch("setMeta", { id: record._id, meta: { draft: true } });
  return record;
}

export async function fetch({ commit, dispatch }, id) {
  const { data } = await $xhr(`/inspections/${id}`);
  if (!data) return;

  const record = data.data.attributes;
  const included = data.included;

  commit("insert", record);

  const thirdpartySignons = includedOfType("thirdparty", included);
  const workerSignons = includedOfType("workerSignon", included);
  const attachments = includedOfType("formAttachment", included);

  commit("signons/insert", thirdpartySignons, ROOT);
  commit("signons/insert", workerSignons, ROOT);
  commit("form_attachments/insert", attachments, ROOT);

  dispatch("customForms/insertIncludedCustomForm", included, ROOT);
}

export async function fetchRecent({ dispatch }) {
  const { data } = await $xhr("/inspections");
  if (!data) return;

  const records = get(data, "data", []).map(n => n.attributes);
  dispatch("insert", records);
}

export async function removeRecord({ dispatch }, id) {
  dispatch("destroyMeta", id);
  dispatch("removeRelated", id);
  dispatch("destroy", id);
}

export async function removeRelated({ dispatch, rootGetters }, id) {
  for (let store of ["signons", "form_attachments"]) {
    const records = rootGetters[`${store}/forRecord`]({
      id,
      type: "inspection"
    });
    records.forEach(rec => dispatch(`${store}/destroy`, rec.id, ROOT));
  }
}

export async function fetchUnitNumbers({ commit }) {
  const { data } = await $xhr("/asset_types/unit_numbers");
  if (data && data.unitNumbers) {
    commit("setUnitNumbers", data.unitNumbers);
  }
}

export function closeout({ dispatch }, id) {
  const lockedAt = new Date().toISOString();
  dispatch("update", { id, attributes: { lockedAt } });
  dispatch("unsetMeta", { id, prop: "draft" });
  dispatch(
    "autosync/enqueue",
    {
      store: "inspections",
      action: "persist",
      id
    },
    { root: true }
  );
}

export { persist } from "./persistence";
