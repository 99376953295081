<template>
  <v-card height="100%">
    <app-toolbar title="Edit Safety Meeting" extended>
      <template v-slot:left>
        <v-btn text icon @click="handleBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:right>
        <v-btn
          small
          color="red"
          class="white--text"
          @click="handleCloseOut"
          :disabled="!canClose"
        >
          Close
        </v-btn>
      </template>
      <template v-slot:extension>
        <v-tabs v-model="activeTab" slider-color="pink" grow>
          <v-tab>General</v-tab>
          <v-tab>Signatures</v-tab>
          <v-tab>Attachments</v-tab>
        </v-tabs>
      </template>
    </app-toolbar>

    <div v-if="meeting">
      <DiscardRecordDialog v-bind="discardDialog" />

      <CloseOutDialog
        v-if="closeOutDialog"
        :reportId="this.recordId"
        store="safetymeetings"
        home="/safetymeetings/list"
        @dismissCloseout="closeOutDialog = false"
      />

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <Form :meetingId="recordId" />
        </v-tab-item>
        <v-tab-item>
          <SignaturesTab v-bind="recordInfo" />
        </v-tab-item>
        <v-tab-item>
          <AttachmentsTab v-bind="recordInfo" />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <NotFound v-else />
  </v-card>
</template>

<script>
import Form from "../components/Form";
import SignaturesTab from "@/components/SignaturesTab";
import AttachmentsTab from "@/components/AttachmentsTab";
import CloseOutDialog from "@/components/CloseOutDialog";
import DiscardRecordDialog from "@/components/DiscardRecordDialog";
import NotFound from "@/components/NotFound";

export default {
  components: {
    Form,
    SignaturesTab,
    AttachmentsTab,
    CloseOutDialog,
    DiscardRecordDialog,
    NotFound
  },
  props: ["recordId"],
  data() {
    return {
      activeTab: 0,
      closeOutDialog: false,
      discardDialog: {
        show: false,
        title: "Incomplete Safetymeeting",
        body: "Meeting Type and a Site are required.",
        handleDiscard: this.discardMeeting,
        handleContinue: () => (this.discardDialog.show = false)
      }
    };
  },
  methods: {
    handleBack() {
      if (this.meeting && (!this.meeting.meetingType || !this.meeting.siteId)) {
        this.discardDialog.show = true;
        return;
      } else {
        this.$router.push("/safetymeetings");
      }
    },
    discardMeeting() {
      this.$store.dispatch("safetymeetings/removeRecord", this.recordId);
      this.$router.push("/safetymeetings");
    },
    handleCloseOut() {
      this.closeOutDialog = true;
    }
  },
  computed: {
    recordInfo() {
      return {
        recordId: this.recordId,
        recordType: "safetymeeting",
        storeName: "safetymeetings"
      };
    },
    meeting() {
      return this.$store.getters["safetymeetings/find"](this.recordId);
    },
    discussions() {
      return this.$store.getters["safetymeeting_items/forRecord"]({
        id: this.recordId,
        type: "discussion"
      });
    },
    canClose() {
      return (
        this.meeting &&
        this.meeting.siteId &&
        this.meeting.meetingType &&
        this.discussions.length > 0
      );
    }
  }
};
</script>
