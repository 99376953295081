import RootView from "../views/Root";
import List from "../views/List";
import Edit from "../views/Edit";
import Show from "../views/Show";
import { inspectionTypes } from "@/domains/inspections/lib";

// valid to.params.inspectionType
function validInspectionType(value) {
  return Object.keys(inspectionTypes).includes(value);
}

export default {
  path: "/inspections",
  component: RootView,
  redirect: "/inspections/list",
  children: [
    {
      name: "inspections",
      path: "list",
      component: List,
      props: true
    },
    {
      name: "inspections_of_type",
      path: "list/:inspectionType",
      component: List,
      props: true,
      beforeEnter: (to, from, next) => {
        if (validInspectionType(to.params.inspectionType)) {
          next();
        } else {
          next("/inspections/list/inspection");
        }
      }
    },
    {
      name: "edit_inspection",
      path: "edit/:recordId",
      component: Edit,
      props: true
    },
    {
      name: "show_inspection",
      path: ":recordId",
      component: Show,
      props: true
    }
  ]
};
