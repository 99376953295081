<template>
  <div>
    <FormGeneral
      :meetingId="meetingId"
      v-on:valid="value => $emit('valid', value)"
    />
    <div class="pb-4">
      <ItemsList
        v-for="type in ['discussion', 'concern', 'action']"
        :key="type"
        :type="type"
        :items="forRecord({ id: meetingId, type })"
        @edit-item="editItem"
        @addItem="addItem"
      />
    </div>

    <ItemDialog
      v-if="itemInfo"
      :itemInfo="itemInfo"
      @dismiss="itemInfo = null"
    />
  </div>
</template>

<script>
import FormGeneral from "./FormGeneral";
import ItemDialog from "./ItemDialog";
import ItemsList from "./ItemsList";

import { mapGetters } from "vuex";

export default {
  props: ["meetingId", "activeTab"],
  components: {
    ItemsList,
    FormGeneral,
    ItemDialog
  },
  data() {
    return {
      itemInfo: null
    };
  },
  computed: {
    ...mapGetters("safetymeeting_items", ["forRecord"])
  },
  methods: {
    editItem(item) {
      this.itemInfo = { id: item.id };
    },
    addItem(type) {
      this.itemInfo = {
        itemType: type,
        safetymeetingId: this.meetingId
      };
    }
  }
};
</script>
