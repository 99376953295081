<template>
  <div>
    <v-card class="pa-3" flat>
      <v-form v-model="valid">
        <div class="blue-grey--text" ref="siteAndLocation">
          Site &amp; Location
        </div>

        <!-- Date -->
        <v-menu
          v-model="showDatePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          ref="datePickerMenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="flha.attributes.date"
              label="Date"
              readonly
              v-on="on"
              :rules="dateRules"
            />
          </template>
          <v-date-picker v-model="flha.attributes.date">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="showDatePicker = false"
              >Cancel</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="$refs.datePickerMenu.save(flha.attributes.date)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-menu>

        <!-- Sites -->
        <v-select
          v-model="flha.attributes.siteId"
          :items="sites"
          item-text="location"
          item-value="id"
          :rules="[v => !!v || 'Required']"
          label="Choose Site"
          required
          data-cy="siteIdInput"
          :menu-props="{ 'content-class': 'siteSelectMenu' }"
        />

        <!-- Location -->
        <LocationInput
          :value="flha.attributes.location"
          @input="value => (flha.attributes.location = value)"
          :locations="locations"
        />

        <!-- Permit -->
        <v-text-field
          label="Permit Number"
          v-model="flha.attributes.permitNumber"
        />

        <!-- Work to be done : Text Area  -->
        <v-textarea
          label="Work to be done"
          v-model="flha.attributes.workPlanned"
          rows="2"
          auto-grow
          no-resize
        ></v-textarea>

        <!-- Muster Point : Text Field  -->
        <v-text-field
          label="Muster Point"
          v-model="flha.attributes.musterPoint"
        />

        <!-- Weather : Text Area  -->
        <v-text-field
          label="Weather"
          v-model="flha.attributes.weatherConditions"
        />

        <!-- Wind Direction : Select -->
        <v-select
          :items="wind_directions"
          label="Wind"
          v-model="flha.attributes.windDirection"
        />

        <!-- Working Alone : Yes/No -->
        <YesNoInput
          label="Working Alone?"
          v-model="flha.attributes.workingAlone"
          @input="e => clearTextarea(e, 'workingAloneExplained')"
        />

        <!-- Working Alone: Explanation : Text Area  -->
        <v-textarea
          :disabled="flha.attributes.workingAlone !== true"
          label="Explain"
          v-model="flha.attributes.workingAloneExplained"
          rows="2"
          auto-grow
          no-resize
          :rules="conditionalyRequired('workingAlone')"
        />

        <!-- PPE Inspected : Yes/No -->
        <YesNoInput
          label="P.P.E. Inspected?"
          v-model="flha.attributes.ppeInspected"
        />

        <!-- Warning Ribbon Required : Yes/No -->
        <YesNoInput
          label="Warning ribbon needed?"
          v-model="flha.attributes.ribbon"
        />

        <!-- Pre-use Inspection Completed: Yes/No -->
        <YesNoInput
          label="Has a pre-use inspection of tools\equipment been completed?"
          v-model="flha.attributes.preUseInspection"
        />

        <!-- Task/Hazard Assessment Follow Up/Review -->
        <h4 class="mt-3">
          Task/Hazard Assessment Follow Up/Review
        </h4>
        <!-- First Break: Text Area -->
        <v-textarea
          label="First Break"
          :value="flha.attributes.reviewFollowup.first_break"
          @change="e => updateReviewFollowup('first_break', e)"
          rows="2"
          no-resize
          auto-grow
        />

        <!-- Second Break: Text Area -->
        <v-textarea
          label="Lunch Break"
          :value="flha.attributes.reviewFollowup.lunch_break"
          @change="e => updateReviewFollowup('lunch_break', e)"
          rows="2"
          no-resize
          auto-grow
        />

        <!-- Third Break: Text Area -->
        <v-textarea
          label="Third Break"
          :value="flha.attributes.reviewFollowup.third_break"
          @change="e => updateReviewFollowup('third_break', e)"
          rows="2"
          no-resize
          auto-grow
        />

        <!-- Hazards Selection : Many Checkboxes -->
        <h4 class="mt-3" ref="hazardsIdentified">Hazards Identified</h4>
        <HazardsChosenList
          :recordId="flha.id"
          @click="showHazardPicker = true"
        />
        <v-btn
          @click="showHazardPicker = true"
          small
          outlined
          color="primary"
          block
          data-cy="editHazardsButton"
          >Choose Hazards</v-btn
        >

        <v-dialog v-model="showHazardPicker" fullscreen>
          <v-card data-cy="hazardChoices">
            <app-toolbar title="Hazard Identification" :backButton="false">
              <template v-slot:right>
                <v-btn
                  text
                  icon
                  @click="showHazardPicker = false"
                  data-cy="closeHazardChoices"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </app-toolbar>
            <div class="mt-12"></div>
            <div
              v-for="(hazardCategory, id) in hazardOptions"
              :key="id"
              class="pa-4 pt-6"
            >
              <span class="body-2">{{ hazardCategory.name }}</span>
              <div class="font-weight-light">
                <template v-for="hazard in hazardCategory.choices">
                  <v-checkbox
                    v-model="flha.attributes.hazardChoiceIds"
                    :key="hazard.id"
                    :value="hazard.id"
                    :label="hazard.name"
                    class="ma-0"
                  />
                </template>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-divider />
        <div id="task-steps">
          <h4 class="mt-6" ref="taskSteps">Task Steps</h4>
          <TaskStepSummary
            :taskSteps="taskSteps"
            :showActions="true"
            @click="taskStep => (showTaskStepEditor = taskStep)"
          />
          <v-btn
            @click="addTaskStep"
            small
            outlined
            color="primary"
            block
            data-cy="addTaskStep"
            >Add Task Step</v-btn
          >
          <v-dialog :value="showTaskStepEditor" fullscreen persistent>
            <TaskStep
              v-if="showTaskStepEditor"
              :taskStepData="showTaskStepEditor"
              @close="showTaskStepEditor = false"
            />
          </v-dialog>
        </div>
      </v-form>
    </v-card>

    <v-footer app color="orange lighten-1" dark class="pa-0">
      <v-btn
        block
        color="dark"
        text
        dark
        v-if="!flha.attributes.siteId"
        @click="$vuetify.goTo($refs.siteAndLocation)"
        data-cy="siteIdRequired"
      >
        Specify Site <v-icon>mdi-arrow-top-right</v-icon>
      </v-btn>
      <v-btn
        block
        color="dark"
        text
        dark
        v-if="flha.attributes.hazardChoiceIds.length === 0"
        @click="$vuetify.goTo($refs.hazardsIdentified)"
        data-cy="hazardIdsRequired"
      >
        Identify Hazards <v-icon>mdi-arrow-top-right</v-icon>
      </v-btn>
      <v-btn
        block
        color="dark"
        text
        dark
        v-if="taskSteps.length === 0"
        @click="$vuetify.goTo($refs.taskSteps)"
        data-cy="taskStepsRequired"
      >
        Add Task Steps <v-icon>mdi-arrow-top-right</v-icon>
      </v-btn>
    </v-footer>
  </div>
</template>

<script>
import TaskStep from "@/components/TaskStep";
import TaskStepSummary from "@/components/TaskStepSummary";
import cloneDeep from "lodash/cloneDeep";
import YesNoInput from "./YesNoInput";
import debounce from "lodash/debounce";
import HazardsChosenList from "./HazardsChosenList";
import { DateTime } from "luxon";
import { sites, locations } from "@/mixins/forms.js";
import { newTaskStep } from "@/models/TaskStep";
import LocationInput from "@/components/LocationInput";

function pastOrPresent(dateString) {
  return DateTime.fromISO(dateString) <= DateTime.local();
}

export default {
  components: {
    TaskStep,
    YesNoInput,
    HazardsChosenList,
    TaskStepSummary,
    LocationInput
  },
  data() {
    return {
      valid: false,
      dateRules: [v => pastOrPresent(v) || "Date can't be in the future"],
      showDatePicker: false,
      showHazardPicker: false,
      showTaskStepEditor: false,
      wind_directions: ["N", "S", "E", "W", "NE", "NW", "SE", "SW"],
      flha: {
        id: this.$route.params.recordId,
        attributes: cloneDeep(
          this.$store.state.flhas.records[this.$route.params.recordId]
        )
      }
    };
  },
  watch: {
    flha: {
      handler: debounce(function() {
        this.$store.commit("flhas/update", cloneDeep(this.flha));
      }, 300),
      deep: true
    }
  },
  computed: {
    sites,
    locations,
    hazardOptions() {
      return this.$store.state.flhas.hazardOptions;
    },
    taskSteps() {
      return this.$store.getters["taskSteps/forRecord"]({ id: this.flha.id });
    }
  },
  methods: {
    updateReviewFollowup(prop, value) {
      this.flha.attributes.reviewFollowup[prop] = value;
    },
    async addTaskStep() {
      this.showTaskStepEditor = newTaskStep();
      this.showTaskStepEditor.flhaId = this.flha.id;
    },
    clearTextarea(value, attribute) {
      if (value === false) this.flha.attributes[attribute] = "";
    },
    conditionalyRequired(attribute) {
      if (this.flha.attributes[attribute] === true) {
        return [v => !!v || "Required"];
      } else {
        return [];
      }
    }
  }
};
</script>

<style scoped>
#task-steps {
  margin-bottom: 42px;
}
</style>
