export function clientIsCompatible() {
  const errors = [];

  // critical to basic PWA functionality
  if ("ServiceWorker" in window === false) {
    errors.push(new Error("Missing ServiceWorker API"));
  }

  // Used to cache images offline
  // ie: employee profiles, custom_form images
  if ("caches" in window === false) {
    errors.push(new Error("Missing Cache API"));
  }

  // used to store attachments added to documents
  if ("indexedDB" in window === false) {
    errors.push(new Error("Missing IndexDB API"));
  }

  return [errors.length === 0, errors];
}
