import Vue from "vue";
import { calcCompositeId } from "@/domains/inspections/lib";

export function setForms(state, payload) {
  if (!Array.isArray(payload)) {
    throw "inspections.mutations.setForms: Invalid payload; array expected.";
  }
  Vue.set(state, "forms", {});
  for (const form of payload) {
    Vue.set(state.forms, form.id, form);
  }
}

export function setUnitNumbers(state, payload) {
  Vue.set(state, "unitNumbers", payload);
}

/* 
    In order to avoid id collisions between different types of
    inspections we need to use a composite key of type and id.

    Example:
    "inspection.123", "quality_control_inspection.123", "site_inspection.123"
    */

export function insert(state, recordOrRecords) {
  if (!Array.isArray(recordOrRecords)) recordOrRecords = [recordOrRecords];
  recordOrRecords.forEach(record => {
    if (!record.id) record.id = uuidv4();
    record._id = calcCompositeId(record);

    Vue.set(state.records, record._id, record);
  });
}
