const progress = {
  state: {
    inProgress: 0
  },
  mutations: {
    showProgress: state => state.inProgress++,
    hideProgress: state => state.inProgress--,
    resetProgress: state => (state.inProgress = 0)
  }
};
export default progress;
