import extendBaseStore from "@/store/libs/base";
import { forRecord } from "@/store/libs/utils";
import actions from "./actions";
import mutations from "./mutations";

function forRecordFilter(record, _type, id) {
  return record[`flhaId`] == id;
}

export default extendBaseStore({
  getters: {
    forRecord: forRecord(forRecordFilter)
  },
  actions,
  mutations
});
