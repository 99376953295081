<template>
  <v-snackbar
    elevation="12"
    bottom
    :value="true"
    v-if="updateExists"
    :timeout="-1"
    color="primary"
  >
    There are updates pending.
    <v-btn color="orange" text @click="clickHandler">Update</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  props: ["clickHandler", "updateExists"]
};
</script>
