<template>
  <div>
    <v-img :src="url" :lazy-src="url" aspect-ratio="1" class="transparent" />
  </div>
</template>

<script>
const IMAGE_TYPES = /png|gif|jpg|jpeg|svg/;
import miscFileImage from "@/assets/file.svg";
import { getFile } from "@/store/libs/fileDb";

export default {
  props: {
    attachment: {
      type: Object
    }
  },
  data() {
    return {
      url: ""
    };
  },
  computed: {
    isImage() {
      return !!this.attachment.fileType.match(IMAGE_TYPES);
    }
  },
  methods: {
    determineUrl({ url } = { url: null }) {
      if (!this.isImage) {
        this.url = miscFileImage;
        return;
      }

      if (this.attachment.url) {
        this.url = this.attachment.url;
        return;
      }

      this.url = url;
    }
  },
  created() {
    if (this.attachment.dbKey) {
      getFile(this.attachment.dbKey).then(this.determineUrl);
    } else {
      this.determineUrl({ url: null });
    }
  }
};
</script>
