<template>
  <v-layout fill-height>
    <app-toolbar :backButton="true" title="View Reminder" />
    <v-flex v-if="reminder">
      <v-card class="pa-2" height="100%">
        <div class="subheading">
          {{ reminder.title }}<br />
          <span class="font-weight-light">{{ reminder.description }}</span>
        </div>

        <dl>
          <dt>Status</dt>
          <dd class="text-capitalize">{{ reminder.status }}</dd>

          <dt>Site</dt>
          <dd>{{ reminder.siteName }}</dd>

          <dt>Type</dt>
          <dd>{{ reminder.raType }}</dd>

          <dt>Date Due</dt>
          <dd>{{ reminder.dueDate | date }}</dd>

          <dt>Location</dt>
          <dd>{{ reminder.location }}</dd>

          <dt>Frequency</dt>
          <dd>{{ reminder.frequency }}</dd>

          <template v-if="reminder.status !== 'open'">
            <dt>Timeliness</dt>
            <dd>{{ timelinessInEnglish(reminder.timeliness) }}</dd>

            <dt>Completed by</dt>
            <dd>{{ reminder.completedBy }}</dd>

            <template v-if="reminder.raLinkType">
              <dt>Report</dt>
              <dd>
                <router-link :to="reportPath(reminder)">{{
                  reminder.raType
                }}</router-link>
              </dd>
            </template>
          </template>
        </dl>

        <div v-if="comments.length > 0" class="mt-2">
          <div class="font-weight-thin">
            Comments
          </div>

          <v-card v-for="comment in comments" :key="comment.id" flat>
            <v-card-text class="px-0">
              <div>{{ comment.body }}</div>
              <div class="body-1 mt-2">
                By <span class="body-2">{{ comment.employeeName }}</span> on
                <span class="red--text text--lighten-2">{{
                  comment.createdAt | date
                }}</span>
              </div>
            </v-card-text>
            <v-divider />
          </v-card>
        </div>
        <v-card-text class="buttons">
          <v-card-actions class="text-center">
            <v-btn
              v-if="reminder.status == 'open'"
              color="blue"
              text
              @click="promptForMissedComment"
            >
              Mark as Missed
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
      <CommentDialog
        v-if="commentDialog"
        :commentDialog="commentDialog"
        @dismiss="commentDialog = null"
        @setComment="handleDone"
      />
    </v-flex>

    <NotFound v-else />
  </v-layout>
</template>

<script>
//import seLog from "@/common/seLog.js";
import CommentDialog from "@/domains/reminders/components/CommentDialog";
import NotFound from "@/components/NotFound";

const raLinkTypePathMap = {
  safetymeeting: "safetymeetings",
  quality_control_inspection: "inspections",
  site_inspection: "inspections",
  inspection: "inspections",
  flha: "flhas"
};

export default {
  data() {
    return {
      commentDialog: null
    };
  },
  components: {
    CommentDialog,
    NotFound
  },
  computed: {
    reminder() {
      let id = this.$route.params.id;
      return this.$store.getters["required_actions/find"](id);
    },
    comments() {
      const belongingToReminder = comment =>
        comment.commentableId == this.reminder.id &&
        comment.commentableType === "required_action";

      return Object.values(this.$store.state.comments.records).filter(
        belongingToReminder
      );
    }
  },
  methods: {
    reportPath(reminder) {
      if (reminder.raLinkType.includes("inspection")) {
        return `/${raLinkTypePathMap[reminder.raLinkType]}/${
          reminder.raLinkType
        }.${reminder.raLinkId}`;
      } else {
        return `/${raLinkTypePathMap[reminder.raLinkType]}/${
          reminder.raLinkId
        }`;
      }
    },
    timelinessInEnglish(t) {
      if (t == 0) return "On Time";

      let plural = Math.abs(t) > 1 ? "s" : "";
      let timeliness = t > 0 ? "Late" : "Early";

      return `${timeliness}: ${Math.abs(t)} Day${plural}`;
    },

    fetchRequiredAction() {
      this.$store.dispatch("required_actions/fetchOne", this.$route.params.id);
    },

    promptForMissedComment() {
      this.commentDialog = "commentDialog";
    },

    missReminder(comment) {
      this.$store.dispatch(`required_actions/setDraft`, {
        id: this.reminder.id,
        ...this.missed(comment)
      });
      this.$store.dispatch(`required_actions/missReminder`, {
        id: this.reminder.id
      });
    },

    missed(comment) {
      return {
        draft: {
          status: "missed",
          comment: { body: comment }
        }
      };
    },

    handleDone(commentText) {
      this.missReminder(commentText);
    }
  },
  mounted() {
    this.fetchRequiredAction();
  }
};
</script>

<style scoped>
.v-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.v-list-item:nth-child(1) {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
