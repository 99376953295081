import extendBaseStore from "@/store/libs/base";
import { includedOfType } from "@/store/libs/utils";
import $xhr from "@/xhr";
import { next } from "lodash-es";
import sortBy from "lodash/sortBy";

export const assetTypes = extendBaseStore({
  actions: {
    async fetchAll({ commit }) {
      const { data } = await $xhr("/asset_types");
      if (!data) return;

      const records = data.data.map(r => r.attributes);
      const assets = includedOfType("asset", data.included);

      commit("insert", records);
      commit("assets/insert", assets, { root: true });
    }
  },
  getters: {
    byTypeId: state => typeIds => typeIds.map(id => state.records[id]),
    asOptions: (_state, getters, _rootState, rootGetters) => typeIds => {
      const assetTypes = sortBy(getters["byTypeId"](typeIds), "name");
      const options = [];

      for (const assetType of assetTypes) {
        if (assetType === undefined) continue;

        options.push({ header: assetType.name });
        const assets = sortBy(
          rootGetters["assets/forType"](assetType.id),
          "name"
        );
        for (const asset of assets) {
          options.push({ text: asset.name, value: asset.id });
        }
      }
      return options;
    }
  }
});

export const assets = extendBaseStore({
  getters: {
    forType: state => typeId =>
      Object.values(state.records).filter(asset => asset.assetTypeId === typeId)
  }
});
