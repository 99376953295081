const STYLES = {
  default: {
    textColor: "black--text text--lighten-1",
    closeColor: "pink",
    bgColor: "white"
  },
  success: {
    textColor: "white--text",
    closeColor: "black",
    bgColor: "primary"
  },
  error: { textColor: "white--text", closeColor: "pink", bgColor: "black" }
};

const SNACKBAR_DEFAULTS = {
  display: false,
  text: "",
  style: STYLES.default
};

function setState(state, text, style) {
  state.text = text;
  state.style = STYLES[style];
  state.display = true;
}
export default {
  namespaced: true,
  state: SNACKBAR_DEFAULTS,
  mutations: {
    show: (state, text) => setState(state, text, "default"),
    success: (state, text) => setState(state, text, "success"),
    error: (state, text) => setState(state, text, "error"),
    clear: state => {
      state.display = false;
    }
  }
};
