<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" left :color="colors[status]">
        {{ icons[status] }}
      </v-icon>
    </template>
    <span>{{ tooltip[status] }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: Object,
      default: () => ({
        goodText: "Good?",
        badText: "Bad?"
      })
    },
    status: {
      type: String,
      default: "na"
    }
  },
  data() {
    return {
      icons: {
        good: "mdi-check-circle",
        bad: "mdi-close",
        na: "mdi-minus-circle"
      },
      colors: {
        good: "success",
        bad: "error",
        na: ""
      },
      tooltip: {
        good: this.text.goodText,
        bad: this.text.badText,
        na: "Not Applicable"
      }
    };
  }
};
</script>

<style></style>
