const navDrawer = {
  state: {
    showNavDrawer: false
  },
  mutations: {
    showNavDrawer: state => (state.showNavDrawer = true),
    setNavDrawerState: (state, payload) => (state.showNavDrawer = payload)
  },
  getters: {
    showNavDrawer: state => state.showNavDrawer
  }
};

export default navDrawer;
