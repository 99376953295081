import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import HomeView from "@/views/Home";
import ContactsView from "@/views/Contacts";
import SignInView from "@/views/SignIn";

// modules
import reminders from "@/domains/reminders/router";

// domains
import safetymeetings from "@/domains/safetymeetings/router";
import flhas from "@/domains/flhas/router";
import inspections from "@/domains/inspections/router";

// store
import store from "@/store/index";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: HomeView
    },
    {
      path: "/signin",
      component: SignInView
    },
    {
      path: "/contacts",
      component: ContactsView
    },
    inspections,
    reminders,
    safetymeetings,
    flhas
  ]
});

// will redirect to sigin if session.token missing; particularly on intial app load
router.beforeEach((to, from, next) => {
  if (!store.state.session.token && to.path !== "/signin") next("/signin");
  else next();
});

export default router;
