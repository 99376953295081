<template>
  <router-view />
</template>

<script>
export default {
  created() {
    this.$store.dispatch("customForms/fetchAll");
    this.$store.dispatch("sites/fetchAll");
    this.$store.dispatch("assetTypes/fetchAll");
    this.$store.dispatch("inspections/fetchUnitNumbers");
    this.$store.dispatch("locations/fetchAll");
  }
};
</script>
