<template>
  <v-container fluid fill-height justify-center class="se-dark-blue">
    <v-flex xs12 sm10 md8 lg6>
      <v-card elevation="3" class="pa-3" v-if="clientIsCompatible[0]">
        <div class="my-4">
          <div>
            <img :src="require('@/assets/logo.png')" class="logo" />
          </div>
          <div class="display-1 font-weight-thin">Safety<br />Evolution</div>
        </div>
        <v-form v-model="valid" @submit.prevent="submitForm">
          <div class="body-2">Client Sign In</div>
          <v-text-field
            v-model="credentials.email"
            :rules="emailRules"
            label="Email"
            required
            append-icon="mdi-email"
            autofocus
            autocapitalize="off"
            autocomplete="username"
            data-cy="login"
          ></v-text-field>
          <v-text-field
            v-model="credentials.password"
            label="Password"
            append-icon="mdi-lock"
            type="password"
            autocomplete="current-password"
            data-cy="password"
          ></v-text-field>
          <v-btn
            :disabled="!valid"
            color="success"
            type="submit"
            data-cy="signInButton"
            block
          >
            Sign In
          </v-btn>
        </v-form>
      </v-card>
      <v-card elevation="3" class="pa-6" v-else>
        <div class="text-center mb-4">
          <v-icon x-large>mdi-alert-circle-outline</v-icon>
        </div>
        <p>
          You have an incompatible browser or operating system.
        </p>
        <h4>Requirements:</h4>
        <p>
          Android Chrome: v43 or newer<br />
          Safari iOS: v11.3 or newer<br />
        </p>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import { clientIsCompatible } from "@/common/compatible";

export default {
  data: () => ({
    valid: false,
    credentials: {
      email: "",
      password: ""
    },
    emailRules: [
      v => !!v || "Email is required",
      v => /.+@.+/.test(v) || "Email address is invalid"
    ]
  }),
  computed: {
    clientIsCompatible
  },
  methods: {
    submitForm() {
      this.$store
        .dispatch("signIn", { ...this.credentials })
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {
          this.$store.commit("snackbar/show", "Invalid email or password.");
          this.credentials.password = "";
        });
    }
  },
  created() {
    this.$store.commit("signOut");
  }
};
</script>

<style scoped>
.logo {
  max-width: 2cm;
  float: left;
  margin-right: 4mm;
}
</style>
