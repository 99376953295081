import { DateTime } from "luxon";
// accept either an ISO 8601 string or a
// Luxon date object; returns formatted
// date time string.
// format api and presets
// https://moment.github.io/luxon/docs/manual/formatting#presets
export default function(value, format = "DATE_HUGE") {
  if (value) {
    value = typeof value == "string" ? DateTime.fromISO(value) : value;
    return value.toLocaleString(DateTime[format]);
  }
}
