<template>
  <v-snackbar :value="display" @input="closeSnackbar" :color="style.bgColor">
    <span :class="style.textColor">{{ text }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        :color="style.closeColor"
        text
        @click="closeSnackbar"
        >Close</v-btn
      >
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    display() {
      return this.$store.state.snackbar.display;
    },
    text() {
      return this.$store.state.snackbar.text;
    },
    style() {
      return this.$store.state.snackbar.style;
    }
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("snackbar/clear");
    }
  }
};
</script>
