<template>
  <v-app-bar color="white" :app="app" dense flat>
    <template v-slot:extension v-if="extended">
      <slot name="extension" />
    </template>
    <slot name="left">
      <v-btn text icon v-if="backButton" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </slot>
    <slot name="title">
      <v-toolbar-title v-if="title" class="font-weight-light text-capitalize">
        {{ title }}
      </v-toolbar-title>
    </slot>
    <v-spacer />
    <slot name="right" v-if="menuButton">
      <v-app-bar-nav-icon
        @click="$store.commit('showNavDrawer')"
        data-cy="navMenu"
      >
        <slot name="default"><v-icon>mdi-menu</v-icon></slot>
      </v-app-bar-nav-icon>
    </slot>
  </v-app-bar>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    backButton: {
      type: Boolean,
      default: false
    },
    menuButton: {
      type: Boolean,
      default: true
    },
    app: {
      type: Boolean,
      default: true
    },
    extended: {
      type: Boolean,
      default: false
    }
  }
};
</script>
