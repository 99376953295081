import extendBaseStore from "@/store/libs/base";
import * as actions from "./actions";
import * as mutations from "./mutations";

export default extendBaseStore({
  state: {
    hazardOptions: {}
  },
  mutations: { ...mutations },
  actions: { ...actions }
});
