import $xhr from "@/xhr";
import get from "lodash/get";
import S3Upload from "@/common/s3upload.js";
import { notifyRequestError } from "@/bugsnag";

function buildPayload(record, uploaderResult) {
  return {
    signon: {
      uuid: record.id,
      employee_id: record.employeeId,
      name: record.name,
      signonable_id: record.signonableId,
      signonable_type: record.signonableType,
      signature: JSON.stringify({
        id: uploaderResult.key,
        storage: "cache",
        metadata: {
          size: uploaderResult.blob.size,
          mime_type: record.fileType,
          filename: record.fileName
        }
      })
    }
  };
}

export default async function persist({ dispatch, getters, rootGetters }, id) {
  const record = getters["find"](id);
  if (!record) return Error("Not Found");

  // Upload signature to S3
  const uploader = new S3Upload(record.url, "signature.png");
  const uploaderResult = await uploader.save();

  if (uploaderResult.error) {
    notifyRequestError(uploaderResult.error);

    const tryCount = rootGetters["autosync/tryCount"](id);
    const retryLimit = process.env.VUE_APP_RETRY_LIMIT || 2;

    if (tryCount > retryLimit) {
      const problem = {
        type: "signon",
        typeId: id,
        errors: uploaderResult.error
      };
      dispatch("problems/new", problem, { root: true });
      return;
    } else {
      throw uploaderResult.error;
    }
  }

  // Post to backend
  const payload = buildPayload(record, uploaderResult.result);
  const result = await $xhr.post(`/signons`, payload);

  // handle success/failure
  if (!(result instanceof Error)) {
    // SUCCESS! insert the new record/delete the old
    const attributes = result.data.data.attributes;
    dispatch("insert", attributes);
    dispatch("destroy", id);
  } else {
    // throw Network Error so autosync will retry
    if (result.networkError) throw "Network Error";

    // Server responded with an error; create a problem record
    const response = result.response;
    const errors = get(response, "data.errors", [response.statusText]);
    const problem = { type: "signon", typeId: id, errors };
    dispatch("problems/new", problem, { root: true });
    notifyRequestError(result);
  }
}
