import get from "lodash/get";
import Vue from "vue";
import extendBaseStore from "@/store/libs/base";
import { includedOfType } from "@/store/libs/utils";
import { Safetymeeting } from "./libs/Safetymeeting";
import uuidv4 from "uuid/v4";
import { notifyRequestError } from "@/bugsnag";
import $xhr from "@/xhr";
import { removeDraftRA } from "@/store/libs/persistence";

export function createSafetymeeting() {
  const timestamp = new Date();

  return {
    id: uuidv4(),
    createdBy: "",
    meetingType: "",
    location: "",
    multipleCa: false,
    lockedAt: null,
    createdAt: timestamp.toISOString(),
    updatedAt: timestamp.toISOString(),
    siteId: null,
    siteName: ""
  };
}

const findStaleIds = (stateRecords, newRecords) => {
  const stateIds = Object.keys(stateRecords);
  const newIds = newRecords.map(rec => rec.id);

  return stateIds.filter(id => newIds.includes(id));
};

const root = { root: true };

export default extendBaseStore({
  state: {
    meetingTypes: []
  },
  mutations: {
    setMeetingTypes(state, data) {
      Vue.set(state, "meetingTypes", data);
    }
  },
  actions: {
    // called by UI to closedout a safetymeeting
    closeout({ dispatch }, id) {
      const lockedAt = new Date().toISOString();
      dispatch("update", { id, attributes: { lockedAt } });
      dispatch("unsetMeta", { id, prop: "draft" });
      dispatch(
        "autosync/enqueue",
        {
          store: "safetymeetings",
          action: "persist",
          id
        },
        root
      );
    },

    // called by autosync handler to persist safetymeetings
    async persist({ dispatch, rootGetters, commit }, id) {
      const meeting = new Safetymeeting(id);
      if (!meeting.record) return Error("Not Found");

      let result = await $xhr.post("/safetymeetings", meeting.reportData());

      if (!(result instanceof Error)) {
        let { data } = result.data;
        let { included } = result.data;

        const newId = data.id;
        dispatch("insert", data.attributes);
        meeting.updateRelatedIDs(newId);
        meeting.updateSafetymeetingItems(included);
        dispatch("destroy", id);
        const newMeeting = new Safetymeeting(newId);
        newMeeting.queueRelated();

        // clean up any related reminder draft data
        removeDraftRA("safetymeeting", id, { rootGetters, commit });
      } else {
        if (result.networkError) throw "Network Error";

        const response = result.response;
        const errors = get(response, "data.errors", [response.statusText]);
        const problem = { type: "safetymeeting", typeId: id, errors };
        dispatch("problems/new", problem, root);
        dispatch("setMeta", { id, meta: { draft: true } });
        notifyRequestError(result);
      }
    },
    async fetchRecent({ commit, state, dispatch }) {
      const response = await $xhr("/safetymeetings");
      const data = get(response, "data.data", []);
      if (!data) return;

      const records = data.map(n => n.attributes);

      // insertUpdate records
      commit("insert", records);

      // prune stale records
      for (let idx in findStaleIds(state.records, records)) {
        dispatch.destroy(idx);
      }
    },
    async fetch({ commit }, meetingId) {
      if (isNaN(Number(meetingId))) return;

      const response = await $xhr(`/safetymeetings/${meetingId}`);
      const record = get(response, "data.data.attributes", undefined);
      if (!record) return;

      const includedRecords = get(response, "data.included", undefined);

      // update the record in records
      commit("insert", record);

      // insert items to item store
      const items = includedOfType("safetymeetingItem", includedRecords);
      const thirdpartySignons = includedOfType("thirdparty", includedRecords);
      const workerSignons = includedOfType("workerSignon", includedRecords);
      const attachments = includedOfType("formAttachment", includedRecords);

      commit("safetymeeting_items/insert", items, root);
      commit("signons/insert", thirdpartySignons, root);
      commit("signons/insert", workerSignons, root);
      commit("form_attachments/insert", attachments, root);
    },
    async fetchMeetingTypes({ commit }) {
      const response = await $xhr("/safetymeetings/types");
      if (response.data) commit("setMeetingTypes", response.data);
    },

    destroyRelated({ dispatch, rootGetters }, id) {
      const forRecord = { id, type: "safetymeeting" };
      const findAndDestroyRelated = store => {
        rootGetters[`${store}/forRecord`](forRecord).forEach(record =>
          dispatch(`${store}/destroy`, record.id, root)
        );
      };

      ["signons", "form_attachments", "safetymeeting_items"].forEach(
        findAndDestroyRelated
      );
    },
    removeRecord({ dispatch }, id) {
      dispatch("destroyRelated", id);
      dispatch("destroyMeta", id);
      dispatch("destroy", id);
    },
    create({ dispatch }) {
      return new Promise(resolve => {
        const record = createSafetymeeting();
        dispatch("insert", record);
        dispatch("setMeta", { id: record.id, meta: { draft: true } });
        resolve(record);
      });
    }
  }
});
