import snakeCase from "lodash/snakeCase";
import without from "lodash/without";
import Flha from "@/models/Flha";
import { newTaskStep } from "@/models/TaskStep";

// without(Object.keys(foo), ...["a","c"])
const FLHA_ATTRIBUTES = without(
  Object.keys(Flha()),
  ...["id", "createdAt", "updatedAt"]
);

export function xhrPayload(record) {
  const payload = {};

  // build record payload from record
  for (const attr of FLHA_ATTRIBUTES) {
    payload[snakeCase(attr)] = record[attr];
  }

  payload.user_created_at = record.createdAt;
  payload.user_updated_at = record.updatedAt;

  return { flha: payload };
}

const TASK_STEP_ATTRIBUTES = without(
  Object.keys(newTaskStep()),
  ...["id", "flhaId"]
);

export function taskStepPayload(record) {
  const payload = {};
  for (const attr of TASK_STEP_ATTRIBUTES) {
    payload[snakeCase(attr)] = record[attr];
  }
  return payload;
}
