<template>
  <ul @click="$emit('click')">
    <div v-for="(hazardCategory, category) in hazardsChosen()" :key="category">
      {{ category }}

      <ul>
        <li v-for="choice in hazardCategory.choices" :key="choice.id">
          {{ choice.name }}
        </li>
      </ul>
    </div>
  </ul>
</template>

<script>
export default {
  props: ["recordId"],
  computed: {
    record() {
      return this.$store.state.flhas.records[this.recordId];
    },
    hazardChoiceIds() {
      return this.record.hazardChoiceIds;
    },
    hazardOptions() {
      return this.$store.state.flhas.hazardOptions;
    }
  },
  methods: {
    hazardsChosen() {
      return (this.hazardChoiceIds || []).reduce((memo, choiceId) => {
        let { category, choice } = this.getHazardOption(choiceId);

        if (memo[category.name]) {
          memo[category.name].choices.push(choice);
        } else {
          memo[category.name] = { choices: [choice] };
        }

        return memo;
      }, {});
    },
    getHazardOption(choiceId) {
      let category = null;
      let choice = null;

      category = this.hazardOptions.find(
        category =>
          (choice = category.choices.find(choice => choice.id === choiceId))
      );

      return { category, choice };
    }
  }
};
</script>

<style></style>
