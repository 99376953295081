<template>
  <v-container fluid ma-0 pa-0 id="inspection-edit">
    <app-toolbar title="Edit Inspection" extended>
      <template v-slot:left>
        <v-btn text icon @click="handleBack" data-cy="toolbarBackButton">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:right>
        <v-btn
          small
          color="red"
          class="white--text"
          data-cy="closeOutButton"
          @click="handleCloseOut"
        >
          Close
        </v-btn>
      </template>
      <template v-slot:extension>
        <v-tabs v-model="activeTab" slider-color="pink" grow>
          <v-tab>General</v-tab>
          <v-tab>Signatures</v-tab>
          <v-tab>Attachments</v-tab>
        </v-tabs>
      </template>
    </app-toolbar>

    <div v-if="record">
      <DiscardRecordDialog v-bind="discardDialog" />

      <CloseOutDialog
        v-if="closeOutDialog"
        :reportId="recordId"
        store="inspections"
        home="back"
        @dismissCloseout="closeOutDialog = false"
      />

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <FormTab :recordId="recordId" v-model="validForm" ref="formtab" />
        </v-tab-item>
        <v-tab-item>
          <SignaturesTab v-bind="recordInfo" />
        </v-tab-item>
        <v-tab-item>
          <AttachmentsTab v-bind="recordInfo" />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <NotFound v-else />
  </v-container>
</template>

<script>
import DiscardRecordDialog from "@/components/DiscardRecordDialog";
import FormTab from "./FormTab";
import SignaturesTab from "@/components/SignaturesTab";
import AttachmentsTab from "@/components/AttachmentsTab";
import CloseOutDialog from "@/components/CloseOutDialog";
import NotFound from "@/components/NotFound";

import { isUUID } from "@/store/libs/utils";

export default {
  components: {
    DiscardRecordDialog,
    FormTab,
    SignaturesTab,
    AttachmentsTab,
    CloseOutDialog,
    NotFound
  },
  props: ["recordId"],
  data() {
    return {
      validForm: false,
      activeTab: 0,
      discardDialog: {
        show: false,
        title: "Incomplete Inspection",
        body: "Site & Inspection Form are required.",
        handleDiscard: this.discardRecord,
        handleContinue: () => (this.discardDialog.show = false)
      },
      closeOutDialog: false
    };
  },

  computed: {
    record() {
      return this.$store.state.inspections.records[this.recordId];
    },
    canClose() {
      return this.validForm;
    },
    recordInfo() {
      const record = this.record || {};
      return {
        recordId: record.id,
        recordType: record.type,
        storeName: "inspections"
      };
    }
  },
  methods: {
    handleBack() {
      if (this.record && (!this.record.siteId || !this.record.customFormId)) {
        this.discardDialog.show = true;
      } else {
        this.$router.back();
      }
    },
    discardRecord() {
      this.$router.back();
      this.$store.dispatch("inspections/removeRecord", this.recordId);
    },
    handleCloseOut() {
      this.$refs.formtab.validateForm();
      if (this.validForm) {
        this.closeOutDialog = true;
      } else {
        this.$store.commit(
          "snackbar/show",
          "Can not close out document.\n\nRequired fields are indicated in red."
        );
      }
    }
  },
  created() {
    if (this.record && !isUUID(this.record.id))
      this.$store.dispatch("inspections/fetch", this.recordId);
  }
};
</script>
