<template>
  <v-layout fill-height>
    <app-toolbar :backButton="true" title="View Safety Meeting" />
    <v-flex v-if="meeting">
      <v-card class="pa-2" height="100%">
        <div class="subheading">
          {{ meeting.meetingType }} &mdash;
          <span class="text-no-wrap grey--text text-darken-4">{{
            siteName(meeting.siteId)
          }}</span>
        </div>
        <div class="red--text text--lighten-2 mt-1">
          {{ meeting.createdAt | date }}
        </div>
        <dl class="mt-1">
          <dt>Created</dt>
          <dd>{{ meeting.createdAt | date("DATETIME_MED") }}</dd>

          <dt>Updated</dt>
          <dd>{{ meeting.updatedAt | date("DATETIME_MED") }}</dd>

          <template v-if="meeting.location">
            <dt>Location</dt>
            <dd>{{ meeting.location }}</dd>
          </template>
          <template v-if="meeting.createdBy">
            <dt>Lead</dt>
            <dd>{{ meeting.createdBy }}</dd>
          </template>
          <template>
            <dt>Workers</dt>
            <dd>{{ workerNames }}</dd>
          </template>
        </dl>

        <h4 class="mt-4">Discussions</h4>
        <v-divider />
        <div v-if="discussions.length === 0" class="pa-2 text-xs-center">
          No Discussions
        </div>
        <div
          v-for="item in discussions"
          class="safetymeeting-item"
          :key="item.id"
        >
          <div class="body-2 mt-3">{{ item.title }}</div>
          <div class="body-1">{{ item.description }}</div>
        </div>

        <h4 class="mt-4">Concerns</h4>
        <v-divider />
        <div v-if="concerns.length === 0" class="pa-2 text-xs-center">
          No Concerns
        </div>
        <div v-for="item in concerns" class="safetymeeting-item" :key="item.id">
          <div class="body-2 mt-3">{{ item.title }}</div>
          <div class="body-1">{{ item.description }}</div>
        </div>

        <h4 class="mt-4">Actions</h4>
        <v-divider />
        <div v-if="actions.length === 0" class="pa-2 text-xs-center">
          No Actions
        </div>
        <div v-for="item in actions" class="safetymeeting-item" :key="item.id">
          <div class="body-2 mt-3">{{ item.title }}</div>
          <div class="body-1">{{ item.description }}</div>
        </div>

        <div id="thirdparties" class="mt-2">
          <div class="font-weight-thin">
            {{ thirdparties.length == 0 ? "No " : "" }}Third Party Signatures
          </div>
          <template v-for="signon in thirdparties">
            <div :key="signon.id">
              <v-img :src="signon.url" crossorigin="anonymous" />
              <div class="text-xs-right">{{ signon.name }}</div>
              <v-divider />
            </div>
          </template>
        </div>

        <div id="attachments">
          <div class="font-weight-thin mt-2">
            {{ (attachments || []).length == 0 ? "No " : "" }}Attachments
          </div>
          <v-container fluid :grid-list-md="true">
            <v-layout row wrap>
              <v-flex
                v-for="attachment in attachments"
                :key="attachment.id"
                xs4
              >
                <v-card tile text>
                  <FileThumb :attachment="attachment" />
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </v-card>
    </v-flex>
    <NotFound v-else />
  </v-layout>
</template>

<script>
import FileThumb from "@/components/FileThumb";
import { siteName } from "@/store/sites";
import { mapActions } from "vuex";
import NotFound from "@/components/NotFound";

export default {
  components: { FileThumb, NotFound },
  props: ["recordId"],
  computed: {
    meeting() {
      return this.$store.getters["safetymeetings/find"](this.recordId);
    },
    meta() {
      return this.$store.getters["safetymeetings/meta"](this.recordId);
    },
    discussions() {
      return this.$store.getters["safetymeeting_items/forRecord"]({
        id: this.recordId,
        type: "discussion"
      });
    },
    concerns() {
      return this.$store.getters["safetymeeting_items/forRecord"]({
        id: this.recordId,
        type: "concern"
      });
    },
    actions() {
      return this.$store.getters["safetymeeting_items/forRecord"]({
        id: this.recordId,
        type: "action"
      });
    },
    attachments() {
      return this.$store.getters["form_attachments/forRecord"](this.forRecord);
    },
    workers() {
      return this.signons.filter(signon => signon.employeeId);
    },
    thirdparties() {
      return this.signons.filter(signon => !signon.employeeId);
    },
    signons() {
      return this.$store.getters["signons/forRecord"](this.forRecord);
    },
    workerNames() {
      return this.workers.map(worker => worker.name).join(", ");
    },
    forRecord() {
      return {
        id: this.recordId,
        type: "safetymeeting"
      };
    }
  },
  methods: {
    ...mapActions("safetymeetings", ["fetch"]),
    siteName
  },
  created() {
    if (this.meta && !this.meta.draft) this.fetch(this.recordId);
  }
};
</script>
