<template>
  <v-dialog :value="true" @input="$emit('input', $event)" persistent>
    <v-card>
      <v-card-text>
        <v-form>
          <v-textarea
            v-model="commentText"
            placeholder="Enter your comment..."
            text
            color="blue"
            rows="1"
            auto-grow
            required
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="dismiss">Cancel</v-btn>
        <v-btn
          text
          color="blue"
          :disabled="this.commentText.trim() == ''"
          @click="handleDone"
          >Done</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      commentText: ""
    };
  },
  methods: {
    dismiss() {
      this.$emit("dismiss");
    },
    handleDone() {
      this.$emit("setComment", this.commentText);
      this.dismiss();
    }
  }
};
</script>
