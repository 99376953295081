import extendBaseStore from "@/store/libs/base";
import { forRecord } from "@/store/libs/utils";

export function forRecordFilter(record, type, id) {
  if (type === "safetymeeting") {
    return record.safetymeetingId == id;
  } else {
    return record.itemType === type && record.safetymeetingId == id;
  }
}

export const getters = {
  forRecord: forRecord(forRecordFilter)
};

export default extendBaseStore({
  getters
});
