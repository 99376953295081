import RemindersView from "@/domains/reminders/views/Reminders";
import RemindersListView from "@/domains/reminders/views/List";
import RemindersShowView from "@/domains/reminders/views/Show";
import RequiredActionTab from "@/domains/reminders/components/RequiredActionTab";

export default {
  path: "/reminders",
  component: RemindersView,
  redirect: "/reminders/list/open",
  children: [
    {
      name: "reminders_list",
      path: "list",
      component: RemindersListView,
      children: [
        {
          path: ":status",
          component: RequiredActionTab
        }
      ]
    },
    {
      name: "reminders_show",
      path: "show/:id",
      component: RemindersShowView
    }
  ]
};
