import Vue from "vue";
import get from "lodash/get";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import uuidv4 from "uuid/v4";

const baseStore = {
  namespaced: true,
  state: {
    records: {},
    meta: {},
    errors: {}
  },
  mutations: {
    // overwrite/insert whole record
    insert: (state, recordOrRecords) => {
      if (!Array.isArray(recordOrRecords)) recordOrRecords = [recordOrRecords];
      recordOrRecords.forEach(record => {
        if (!record.id) record.id = uuidv4();
        Vue.set(state.records, record.id, record);
      });
    },
    destroy(state, id) {
      Vue.delete(state.records, id);
    },
    // updates existing record
    update(state, { id, attributes }) {
      if (!id) throw "Missing Id for update";
      let existingRecord = state.records[id];
      state.records[id] = { ...existingRecord, ...attributes };
      state.records[id]["updatedAt"] = new Date().toISOString();
    },
    // specify metaData for a record
    setMeta(state, { id, meta }) {
      const existingMeta = get(state.meta, id, {});
      Vue.set(state.meta, id, { ...existingMeta, ...meta });
    },
    unsetMeta(state, { id, prop }) {
      Vue.delete(state.meta[id], prop);
    },
    // destroy all metaData for a record
    destroyMeta(state, id) {
      Vue.delete(state.meta, id);
    }
  },
  actions: {
    insert({ commit }, recordOrRecords) {
      commit("insert", recordOrRecords);
    },
    update({ commit }, { id, attributes }) {
      commit("update", { id, attributes });
    },
    destroy({ commit }, id) {
      commit("destroy", id);
    },
    setMeta({ commit }, { id, meta }) {
      commit("setMeta", { id, meta });
    },
    unsetMeta({ commit }, { id, prop }) {
      commit("unsetMeta", { id, prop });
    },
    destroyMeta({ commit }, id) {
      commit("destroyMeta", id);
    }
  },
  getters: {
    find: state => id => {
      return state.records[id];
    },
    meta: state => id => {
      return get(state.meta, id, {});
    },
    records: state => Object.values(state.records)
  }
};

export default function extendBaseStore(store) {
  return merge(cloneDeep(baseStore), store);
}
