<template>
  <v-card flat>
    <v-card-text>
      <v-form v-model="validForm" ref="inspectionForm">
        <!-- Inspection Form -->
        <v-select
          v-model="record.customFormId"
          :items="inspectionForms"
          item-text="title"
          item-value="id"
          :rules="[v => !!v || 'Required']"
          label="Choose Inspection Form"
          required
          hint="Required"
          persistent-hint
          data-cy="inspectionForm"
          @change="handleInspectionFormChange"
        />
        <!-- Sites -->
        <v-select
          v-model="record.siteId"
          :items="sites"
          item-text="location"
          item-value="id"
          :rules="[v => !!v || 'Required']"
          label="Choose Site"
          required
          hint="Required"
          persistent-hint
          data-cy="site"
        />

        <!-- Location -->
        <LocationInput
          :value="record.location"
          @input="value => (record.location = value)"
          :locations="locations"
        />

        <!-- Hours/Odometer -->
        <template v-if="record.type === 'inspection'">
          <v-text-field
            v-model="record.hoursOdometer"
            required
            hint="Required"
            persistent-hint
            :rules="[v => !!v || 'Required']"
            label="Hours/Odometer"
            data-cy="hoursOdometer"
          />

          <!-- Unit Number -->
          <v-combobox
            v-model="record.unitNumber"
            v-on:update:searchInput="value => (location = value)"
            v-if="(chosenInspection.assetTypeIds || []).length === 0"
            label="Unit Number"
            required
            hint="Required"
            persistent-hint
            :rules="[v => !!v || 'Required']"
            :items="unitNumbers"
            data-cy="unitNumber"
          />

          <!-- Asset -->
          <v-select
            v-model="record.assetId"
            v-if="(chosenInspection.assetTypeIds || []).length > 0"
            :items="assetOptions"
            :rules="[v => !!v || 'Required']"
            required
            hint="Required"
            persistent-hint
            label="Asset"
            data-cy="asset"
          />
        </template>

        <CustomFormField
          v-for="customField in customFields"
          :key="customField.id"
          :customField="customField"
          v-model="record.customFields[customField.id].fieldValue"
        />

        <!-- Separate Action Items? -->
        <v-switch
          v-model="record.multipleCa"
          ref="switch"
          :label="`Separate Action Items? ${record.multipleCa ? 'On' : 'Off'}`"
          data-cy="multipleCa"
        />

        <!-- Form Questions -->
        <template v-for="part in parts">
          <div :key="part.id">
            <h3>{{ part.title }}</h3>
            <p>{{ part.description }}</p>
            <v-img v-if="part.imageUrl" :src="part.imageUrl" contain />
            <div
              data-cy="formDataInputs"
              v-for="line in lines(part.id)"
              :key="line.id"
            >
              <p class="mt-3 mb-2 body-2">
                {{ line.text }}
              </p>
              <v-radio-group
                class="mt-1"
                v-model="record.formData[line.id].line"
                :rules="[v => !!v || 'Required']"
              >
                <v-radio :label="chosenInspection.goodText" value="good" />
                <v-radio :label="chosenInspection.badText" value="bad" />
                <v-radio label="Not Applicable" value="na" />
              </v-radio-group>
              <v-textarea
                class="mt-0 pt-0"
                v-model="record.formData[line.id].comments"
                :rows="1"
                label="Comments"
                auto-grow
                :rules="commentsRequired(line)"
              />
            </div>
          </div>
        </template>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { sites, locations } from "@/mixins/forms.js";
import cloneDeep from "lodash/cloneDeep";
import { inspectionTypes } from "@/domains/inspections/lib";
import CustomFormField from "@/domains/inspections/components/CustomFormField";
import LocationInput from "@/components/LocationInput";

export default {
  components: { CustomFormField, LocationInput },
  props: ["recordId"],
  data() {
    return {
      validForm: false,
      record: cloneDeep(
        this.$store.state.inspections.records[this.$props.recordId]
      )
    };
  },
  watch: {
    record: {
      handler: function() {
        this.update({ id: this.recordId, attributes: cloneDeep(this.record) });
      },
      deep: true
    },
    validForm(value) {
      this.$emit("input", value);
    }
  },
  computed: {
    sites,
    locations,
    inspectionForms() {
      // identify which custom forms are for this inspection type
      const customForms = Object.values(this.$store.state.customForms.records);
      const validCustomFormTypes = inspectionTypes[this.record.type].formTypes;

      return customForms.filter(form => {
        return (
          validCustomFormTypes.includes(form.formType) &&
          (form.isActive || form.id === this.record.customFormId)
        );
      });
    },
    chosenInspection() {
      return (
        this.$store.state.customForms.records[this.record.customFormId] || {}
      );
    },
    customFields() {
      return this.chosenInspection === null
        ? []
        : this.chosenInspection.customFields;
    },
    assetOptions() {
      if (!this.chosenInspection.assetTypeIds) return [];
      return this.$store.getters["assetTypes/asOptions"](
        this.chosenInspection.assetTypeIds
      );
    },
    unitNumbers() {
      return this.$store.state.inspections.unitNumbers;
    },
    parts() {
      return this.getParts()(this.record.customFormId);
    }
  },
  methods: {
    ...mapActions("inspections", ["update"]),
    ...mapGetters("customForms", ["getParts", "getLines", "formDataTemplate"]),
    lines(partId) {
      return this.getLines()(partId);
    },
    commentsRequired(line) {
      return this.record.formData[line.id].line === "bad"
        ? [v => !!v || "Required"]
        : [];
    },
    handleInspectionFormChange(value) {
      this.record.customFormId = value;

      // build fresh formData object
      this.$set(
        this.record,
        "formData",
        this.formDataTemplate()(this.record.customFormId)
      );

      this.$set(this.record, "customFields", {});
      this.customFields.forEach(cf => {
        this.$set(this.record.customFields, cf.id, { fieldValue: "" });
      });
    },
    validateForm() {
      this.$refs.inspectionForm.validate();
    }
  }
};
</script>
