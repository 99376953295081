<template>
  <div>
    <app-toolbar title="Home Dashboard" />

    <!-- DISPLAY of Company Name & Today's Date -->
    <v-card
      color="blue-grey darken-1"
      class="white--text bottom-gradient mx-2 mb-1"
    >
      <v-card-title primary-title>
        <div>
          <div class="headline">
            {{ $store.state.session.employee.company.name }}
            <span class="font-weight-light text-no-wrap">Dashboard</span>
          </div>
          <div class="subheadline font-weight-light">
            {{ today | date }}
          </div>
        </div>
      </v-card-title>
    </v-card>

    <!-- DISPLAY of Hazard Assessments -->
    <v-card text color="blue-grey darken-2" class="white--text ma-2">
      <v-card-title class="headline  pb-1">
        <span class="font-weight-regular mr-2">Action Items</span>
        <span class="font-weight-light">Hazard Assessments</span>
      </v-card-title>
      <v-card-text class="pt-1">
        <p class="white--text">
          Click the
          <v-icon color="blue" rounded>mdi-plus</v-icon>
          button to start a new Hazard Assessment
        </p>

        <DraftTodoSummary
          title="Safety Meetings"
          :draftCount="draftCount('safetymeetings')"
          :todoCount="toDoCount('Safety Meeting')"
          to="/safetymeetings"
        />

        <DraftTodoSummary
          title="FLHAs"
          :draftCount="draftCount('flhas')"
          :todoCount="toDoCount('FLHA')"
          to="/flhas"
        />
      </v-card-text>
      <v-card-actions style="position: relative;">
        <v-speed-dial class="aligned" absolute right v-model="meetingFab">
          <template v-slot:activator>
            <v-btn color="blue" small dark fab v-model="meetingFab">
              <v-icon v-if="meetingFab">mdi-close</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-btn
            dark
            color="orange darken-4"
            class="my-1"
            @click.stop="newHazardAssessment('safetymeetings')"
            >Safety Meeting</v-btn
          >
          <v-btn
            dark
            color="orange darken-2"
            class="my-1"
            @click.stop="newHazardAssessment('flhas')"
            >FLHA</v-btn
          >
        </v-speed-dial>
      </v-card-actions>
    </v-card>

    <!-- DISPLAY of Inspections -->
    <v-card text color="blue-grey darken-2" class="white--text ma-2 my-6">
      <v-card-title class="headline  pb-1">
        <!-- <span class="font-weight-regular mr-2">Action Items</span> -->
        <span class="font-weight-light">Inspections</span>
      </v-card-title>
      <v-card-text class="pt-1">
        <p class="white--text">
          Click the
          <v-icon color="orange" rounded>mdi-plus</v-icon>
          button to start a new Inspection
        </p>

        <DraftTodoSummary
          title="Equipment & Vehicle"
          :draftCount="eqInspectionDrafts.length"
          :todoCount="toDoCount('Inspection')"
          to="/inspections/list"
          bgColor="blue-grey"
        />

        <DraftTodoSummary
          title="Quality Control"
          :draftCount="qcInspectionDrafts.length"
          :todoCount="toDoCount('Quality Control Inspection')"
          to="/inspections/list/quality_control_inspection"
          bgColor="blue-grey"
        />

        <DraftTodoSummary
          title="Site"
          :draftCount="siteInspectionDrafts.length"
          :todoCount="toDoCount('Site Inspection')"
          to="/inspections/list/site_inspection"
          bgColor="blue-grey"
        />
      </v-card-text>
      <v-card-actions style="position: relative;">
        <v-speed-dial class="aligned" absolute right v-model="inspectionFab">
          <template v-slot:activator>
            <v-btn color="orange" small dark fab v-model="inspectionFab">
              <v-icon v-if="inspectionFab">mdi-close</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-btn
            dark
            color="orange darken-4"
            class="my-1"
            @click.stop="newInspection('inspection')"
            >Equipment or Vehicle</v-btn
          >
          <v-btn
            dark
            color="orange darken-2"
            class="my-1"
            @click.stop="newInspection('quality_control_inspection')"
            >Quality Control</v-btn
          >
          <v-btn
            dark
            color="orange"
            class="my-1"
            @click.stop="newInspection('site_inspection')"
            >Site</v-btn
          >
        </v-speed-dial>
      </v-card-actions>
    </v-card>

    <!-- DISPLAY of PROBLEMS -->
    <v-card
      color="red lighten-1"
      class="white--text bottom-gradient mx-2 mb-1 mt-4"
      v-if="problems.length > 0"
    >
      <v-card-title primary-title class="pb-1">
        <div>
          <div class="headline font-weight-light">
            Autosync Problems
          </div>
          <div class="subheadline">
            Please review the following:
          </div>
        </div>
      </v-card-title>

      <v-card-text class="pt-0">
        <component
          v-for="problem in problems"
          :is="getComponent(problem.type)"
          :key="problem.id"
          :problem="problem"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  components as problemTypeComponents,
  mapper as problemTypeMapper
} from "@/domains/problems/components/types";

import DraftTodoSummary from "@/components/home/DraftTodoSummary";
import { inspectionTypes } from "../domains/inspections/lib";
import { mapGetters } from "vuex";

function filterReminers(type) {
  return function(reminder) {
    return reminder.raType === type && reminder.status === "open";
  };
}

export default {
  name: "Home",
  data() {
    return {
      meetingFab: false,
      inspectionFab: false,
      today: new Date().toISOString()
    };
  },
  components: { problemTypeComponents, DraftTodoSummary },
  computed: {
    ...mapGetters("inspections", [
      "eqInspectionDrafts",
      "qcInspectionDrafts",
      "siteInspectionDrafts"
    ]),
    reminders() {
      return Object.values(this.$store.state.required_actions.records);
    },
    problems() {
      return this.$store.getters["problems/records"];
    }
  },
  methods: {
    draftCount(type) {
      let meta = Object.values(this.$store.state[type].meta);
      return meta.filter(rec => rec.draft === true).length;
    },
    toDoCount(type) {
      return this.reminders.filter(filterReminers(type)).length;
    },
    newHazardAssessment(type) {
      this.$store.dispatch(`${type}/create`).then(record => {
        this.$router.push(`/${type}/edit/${record.id}`);
      });
    },
    newInspection(inspectionType) {
      this.$store
        .dispatch("inspections/create", inspectionType)
        .then(record => {
          this.$router.push(`/inspections/edit/${record._id}`);
        });
    },
    getComponent(problemType) {
      return problemTypeMapper[problemType];
    }
  },
  mounted() {
    this.$store.dispatch("required_actions/fetchAll");
  }
};
</script>

<style scoped>
.actions {
  display: flex;
  flex-direction: row;
}

.subcard a,
.subcard:active,
.subcard:visited {
  color: white;
  text-decoration: none;
}

.details {
  display: flex;
  width: 100%;
}

.details > div {
  width: 50%;
}

.details .subheading {
  padding-top: 4px;
}

.dash-button {
  height: 33%;
  width: 100px;
  color: white;
  border-radius: 2px;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

div.fa {
  height: calc(100% - 30px);
  width: 100%;
  margin: auto;
  padding-top: 16px;
}

div.db-text {
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
}
</style>
