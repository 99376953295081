import $xhr from "@/xhr";
import snakeCase from "lodash/snakeCase";
import without from "lodash/without";
import Inspection from "@/domains/inspections/models/Inspection";
import {
  manifestPayload,
  updateForeignKeys,
  enqueueRelated,
  removeDraftRA
} from "@/store/libs/persistence";

import get from "lodash/get";
import { notifyRequestError } from "@/bugsnag";

const PAYLOAD_ATTRIBUTES = without(
  Object.keys(Inspection()),
  ...["id", "createdAt", "updatedAt", "formData", "type", "customFields"]
);

export async function persist(context, id) {
  const { dispatch, getters, rootGetters } = context;

  const record = getters["find"](id);
  const meta = getters["meta"](id);
  if (!record) return Error("Not Found");

  const forRecord = { id: record.id, type: record.type };
  const signons = rootGetters["signons/forRecord"](forRecord);
  const attachments = rootGetters["form_attachments/forRecord"](forRecord);

  const payload = buildPayload(record);
  payload.inspection.pwa = manifestPayload(record, { signons, attachments });
  payload.data = record.formData;
  payload.type = record.type;
  payload.required_action_id = meta.reminderId;
  payload.inspection.custom_field_values_attributes = customFieldsPayload(
    record.customFields
  );

  // post to back-end
  const result = await $xhr.post("/inspections", payload);

  if (!(result instanceof Error)) {
    const { data } = result.data;
    const newId = data.id;

    // insert persisted record
    dispatch("insert", data.attributes);

    // update foreign keys for signons, form_attachments
    updateForeignKeys(signons, "signons", "signonableId", newId, dispatch);
    updateForeignKeys(
      attachments,
      "form_attachments",
      "reportId",
      newId,
      dispatch
    );

    //  destroy draft record
    dispatch("removeRecord", id);

    // queue signons, form_attachments to be persisted
    enqueueRelated(signons, "signons", dispatch);
    enqueueRelated(attachments, "form_attachments", dispatch);

    // clean up any related reminder draft data
    removeDraftRA(record.type, id, context);
  } else {
    // possibly all this could be extracted out to be reused by all persist() functions
    if (result.networkError) throw "Network Error";

    const response = result.response;
    const errors = get(response, "data.errors", [response.statusText]);
    const problem = { type: "inspection", typeId: id, errors };

    // insert problem record
    dispatch("problems/new", problem, { root: true });

    // revert to draft
    dispatch("setMeta", { id, meta: { draft: true } });

    // trigger bugsnag notification
    notifyRequestError(result);
  }

  function customFieldsPayload(customFields) {
    return Object.keys(customFields).map(cfId => {
      return {
        custom_form_field_id: cfId,
        field_value: customFields[cfId].fieldValue
      };
    });
  }

  function buildPayload() {
    const payload = {};

    // build record payload from record
    for (const attr of PAYLOAD_ATTRIBUTES) {
      payload[snakeCase(attr)] = record[attr];
    }

    payload.user_created_at = record.createdAt;
    payload.user_updated_at = record.updatedAt;

    return { inspection: payload };
  }
}
