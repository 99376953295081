import Vue from "vue";
import $xhr from "@/xhr";
import get from "lodash/get";

export function siteName(siteId) {
  return (
    this.$store.state.sites.records[siteId] || { location: "[Undefined]" }
  ).location;
}

export default {
  namespaced: true,
  state: {
    records: {}
  },
  mutations: {
    insert(state, records) {
      Vue.set(state, "records", records);
    }
  },
  actions: {
    async fetchAll({ commit }) {
      const dataToRecords = (obj, rec) =>
        Object.assign(obj, { [rec.id]: rec.attributes });
      const { data } = await $xhr("/sites");
      if (!data) return;

      const records = get(data, "data", []).reduce(dataToRecords, {});
      commit("insert", records);
    }
  },
  getters: {}
};
