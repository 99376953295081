export const sortCreatedAt = (a, b) =>
  a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;

export const sortByDate = prop => (a, b) =>
  a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0;

export const polymorphicBelongsTo = ({ typeProp, idProp, id, type }) => rec => {
  if (!Array.isArray(type)) type = [type];
  return rec[idProp] == id && type.includes(rec[typeProp]);
};

// reduces rest:api "included" records to an array matching type
export const includedOfType = (typeWanted, records) =>
  records.reduce((results, rec) => {
    if (rec.type == typeWanted) {
      results.push(rec.attributes);
    }
    return results;
  }, []);

export function authToken() {
  try {
    return JSON.parse(localStorage.getItem("vuex")).session.token;
  } catch (error) {
    return "";
  }
}

export function forRecord(filterFn) {
  return state => ({ type, id }) => {
    return Object.values(state.records).filter(record =>
      filterFn(record, type, id)
    );
  };
}

export function isUUID(id) {
  const valid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return valid.test(id);
}

/**
 * Preload and cache images for offline.
 *
 * @param urls { Array } - An array of string URLs that you want to be fetched and added to the cache.
 *
 * @return {undefined}
 */
export async function imageCacheAdd(urls) {
  // remove duplicates, null, and undefined
  urls = [...new Set(urls)].filter(Boolean);

  // delete existing cache entries so we don't accumulate them
  window.caches.open("apiImageCache").then(async cache => {
    for (const url of urls) {
      await cache.delete(url, { ignoreSearch: true });
      cache.add(url).catch(error => {
        console.log("Could not acces/cache resource", url);
      });
    }
  });
}
