import uuidv4 from "uuid/v4";

export function newTaskStep() {
  const record = {
    id: uuidv4(),
    flhaId: null,
    description: null,
    hazards: null,
    severity: 1,
    probability: 1,
    precautionsTaken: null,
    mitigatedSeverity: 1,
    mitigatedProbability: 1
  };

  return record;
}
