<template>
  <v-container fluid>
    <app-toolbar :backButton="true" title="Viewing Inspection" />

    <v-card flat v-if="record">
      <v-alert border="top" color="red lighten-2" dark v-if="!customForm">
        Temporary limited inspection data available.
      </v-alert>

      <v-card-text class="pa-0 ma-0">
        <div class="subtitle-2">
          {{ inspectionTypes[record.type].name }} Inspection
        </div>
        <div class="subheading">
          {{ getTitle }}<br />
          <span class="text-no-wrap grey--text text-darken-4">{{
            siteName(record.siteId)
          }}</span>
          <span v-if="record.location" class="font-weight-light">
            &mdash; {{ record.location }}</span
          >
        </div>
        <div class="red--text text--lighten-2 mt-1">
          {{ record.createdAt | date }}
        </div>

        <dl class="mt-1">
          <template v-if="record.location">
            <dt>Location</dt>
            <dd>{{ record.location }}</dd>
          </template>

          <dt>Created</dt>
          <dd>{{ record.createdAt | date("DATETIME_MED") }}</dd>

          <dt>Updated</dt>
          <dd>{{ record.updatedAt | date("DATETIME_MED") }}</dd>

          <dt>Workers</dt>
          <dd>{{ workerNames }}</dd>

          <template v-if="record.type === 'inspection'">
            <dt>Unit Number</dt>
            <dd>{{ record.unitNumber }}</dd>

            <dt>Hours/Odometer</dt>
            <dd>{{ record.hoursOdometer }}</dd>
          </template>
        </dl>

        <dl
          v-for="(customField, customFieldId) in record.customFields || {}"
          :key="customFieldId"
        >
          <dt>
            {{ customFormField(customFieldId).name }}
          </dt>
          <dd>{{ customField.fieldValue }}</dd>
        </dl>

        <v-divider />

        <InspectionPart
          v-for="part in parts"
          :key="part.id"
          :part="part"
          :lines="getLines()(part.id)"
          :inspectionData="record.formData"
          :goodText="record.goodText"
          :badText="record.badText"
        />

        <div id="thirdparties" class="mt-2">
          <div class="font-weight-thin">
            {{ thirdparties.length == 0 ? "No " : "" }}Third Party Signatures
          </div>
          <template v-for="signon in thirdparties">
            <div :key="signon.id">
              <v-img :src="signon.url" crossorigin="anonymous" />
              <div class="text-xs-right">{{ signon.name }}</div>
              <v-divider />
            </div>
          </template>
        </div>

        <div id="attachments">
          <div class="font-weight-thin mt-2">
            {{ (attachments || []).length == 0 ? "No " : "" }}Attachments
          </div>
          <v-container fluid :grid-list-md="true">
            <v-layout row wrap>
              <v-flex
                v-for="attachment in attachments"
                :key="attachment.id"
                xs4
              >
                <v-card tile text>
                  <FileThumb :attachment="attachment" />
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </v-card-text>
    </v-card>

    <NotFound v-else />
  </v-container>
</template>

<script>
import InspectionPart from "@/domains/inspections/components/InspectionPart";
import FileThumb from "@/components/FileThumb";
import { mapState, mapGetters } from "vuex";
import { siteName } from "@/store/sites";
import { isUUID } from "@/store/libs/utils";
import {
  signons,
  attachments,
  workers,
  thirdparties,
  workerNames
} from "@/mixins/forms.js";
import { inspectionTypes } from "@/domains/inspections/lib";
import NotFound from "@/components/NotFound";

export default {
  components: { FileThumb, InspectionPart, NotFound },
  props: {
    recordId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    getTitle() {
      return this.record.title || this.customForm?.title || "Unavailable";
    },
    forRecord() {
      return { type: this.record.type, id: this.record.id };
    },
    record() {
      return this.$store.getters["inspections/find"](this.recordId);
    },
    meta() {
      return this.$store.getters["inspections/meta"](this.recordId);
    },
    parts() {
      return this.getParts()(this.record.customFormId);
    },
    inspectionTypes: () => inspectionTypes,
    customForm() {
      return this.$store.state.customForms.records[this.record.customFormId];
    },
    signons,
    attachments,
    workers,
    thirdparties,
    workerNames
  },
  methods: {
    siteName,
    ...mapGetters("customForms", ["getParts", "getLines"]),
    customFormField(id) {
      return this.customForm.customFields.find(cf => cf.id == id) || {};
    }
  },
  created() {
    if (!isUUID(this.record.id))
      this.$store.dispatch("inspections/fetch", this.recordId);

    this.$state = this.$store.state.inspections;
  }
};
</script>
