<template>
  <div>
    <component
      :is="componentType"
      :rules="isRequiredRule"
      :label="customField.name"
      :value="value"
      @input="value => $emit('input', value)"
      v-bind="requiredProps"
    />
  </div>
</template>

<script>
const fieldTypeComponentMap = {
  text_field: "v-text-field",
  text_area: "v-textarea"
};

import { VTextField, VTextarea } from "vuetify/lib";

export default {
  props: {
    value: {
      default: ""
    },
    customField: {
      required: true,
      type: Object
    }
  },
  components: { VTextField, VTextarea },
  computed: {
    componentType() {
      return fieldTypeComponentMap[this.customField.field_type];
    },
    isRequiredRule() {
      if (!this.customField.required) return [];
      return [v => !!v || "Required"];
    },
    requiredProps() {
      if (!this.customField.required) return {};

      return {
        required: true,
        hint: "Required",
        "persistent-hint": true
      };
    }
  }
};
</script>

<style></style>
