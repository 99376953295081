import Axios from "axios";
import { xhr } from "@/xhr";

const PRESIGN_ENDPOINT = `${process.env.VUE_APP_API_HOST}/images/cache/presign`;
const $xhr = xhr(Axios);

export default class S3Upload {
  constructor(file, fileName) {
    if (file) {
      Object.assign(this, { file, fileName });
      this.blob = this.calcBlob(file);
    }
  }

  fetchPresign() {
    return $xhr(PRESIGN_ENDPOINT);
  }

  postToS3(data) {
    return $xhr.post(data.url, this.form(data.fields));
  }

  // Convert image as data URL to blob
  calcBlob(file) {
    const [fileType, fileData] = file.split(",");
    const byteString = atob(fileData);
    const content = new Array();
    for (let i = 0; i < byteString.length; i++) {
      content[i] = byteString.charCodeAt(i);
    }

    return new Blob([new Uint8Array(content)], { type: fileType });
  }

  // Construct FormData object from an object.
  // Each property on the fields object is added to the FormData object.
  // Finally, the file being uploaded is also added to the formData object.
  form(fields) {
    const form = new FormData();
    Object.keys(fields).forEach(k => form.append(k, fields[k]));
    form.append("file", this.blob, this.fileName);

    return form;
  }

  // Presign, Upload, and return response object
  async save() {
    let presign, response, result, error;

    try {
      const success = 204;
      presign = await this.fetchPresign();
      response = await this.postToS3(presign.data);

      if (typeof response === "object" && response.status === success) {
        let key = presign.data.fields.key.split("/")[1];
        result = Object.assign({ blob: this.blob, key: key }, presign.data);
      } else {
        error = new Error();
        error.presign = presign;
        error.response = response;
      }

      return { result, error };
    } catch (error) {
      error.presign = presign;
      error.response = response;

      return { result, error };
    }
  }
}
