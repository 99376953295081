<template>
  <v-list-item-action v-if="email || phone || phone2">
    <v-menu>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          <v-icon color="blue">fa-comment-dots</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-if="email" :href="`mailto:${email}`">
          <v-list-item-action>
            <v-icon>mdi-email</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            {{ email }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="phone" :href="`tel:${phone}`">
          <v-list-item-action>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            {{ phone }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="phone2" :href="`tel:${phone2}`">
          <v-list-item-action>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            {{ phone2 }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item-action>
</template>

<script>
export default {
  props: ["phone", "phone2", "email"]
};
</script>
