import uuidv4 from "uuid/v4";

export default function Inspection(type = "inspection") {
  const timestamp = new Date();
  const uuid = uuidv4();
  const record = {
    id: uuid,
    type: type,
    createdAt: timestamp.toISOString(),
    updatedAt: timestamp.toISOString(),
    siteId: null,
    customFormId: null,
    location: "",
    multipleCa: false,
    formData: {},
    customFields: {},
    lockedAt: null
  };

  if (type === "inspection") {
    record.unitNumber = "";
    record.hoursOdometer = "";
    record.assetId = null;
  }

  return record;
}
