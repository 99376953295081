export function signons() {
  return this.$store.getters["signons/forRecord"](this.forRecord);
}

export function attachments() {
  return this.$store.getters["form_attachments/forRecord"](this.forRecord);
}

export function taskSteps() {
  return this.$store.getters["taskSteps/forRecord"](this.forRecord);
}

export function workers() {
  return this.signons.filter(signon => signon.employeeId !== undefined);
}

export function thirdparties() {
  return this.signons.filter(signon => signon.employeeId === undefined);
}

export function workerNames() {
  return this.workers.map(worker => worker.name).join(", ");
}

export function sites() {
  return Object.values(this.$store.state.sites.records);
}

export function locations() {
  return Object.values(this.$store.state.locations.records);
}
