<template>
  <v-dialog value="true" @input="$emit('dismissCloseout')">
    <v-card>
      <v-card-title>
        <span class="subheading">Close Out Document</span>
      </v-card-title>
      <v-card-text>
        <v-list
          v-if="reminders.length"
          two-line
          style="height: 300px; overflow: hidden; overflow-y:scroll"
          class="borders-between"
        >
          <h3>Available Reminders</h3>
          <v-list-item
            v-for="item in reminders"
            :key="item.id"
            @click="reminderId = reminderId == item.id ? null : item.id"
          >
            <v-list-item-action>
              <v-checkbox
                v-model="reminderId"
                :value="item.id"
                readonly
              ></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize">
                {{ item.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="">{{ item.raType }}</span> &mdash;
                <span class="">{{ item.siteName }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span class="red--text text--lighten-3">
                  {{ item.dueDate | date }}</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="$emit('dismissCloseout')"
          >Cancel</v-btn
        >
        <v-spacer />
        <v-btn color="primary" text @click="submit">Close Out</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { sortByDate } from "@/store/libs/utils";

const STORE_TO_TYPE = {
  safetymeetings: "safetymeeting",
  flhas: "flha"
};

const STORE_TO_RA_TYPE = {
  safetymeetings: "Safety Meeting",
  flhas: "FLHA",
  inspection: "Inspection",
  site_inspection: "Site Inspection",
  quality_control_inspection: "Quality Control Inspection"
};

export default {
  props: ["store", "reportId", "home"],
  data() {
    return {
      reminderId: null
    };
  },
  computed: {
    ...mapGetters({
      employee: "employee",
      employeeFullName: "employeeFullName",
      reminderRecords: "required_actions/records"
    }),
    report() {
      return this.$store.getters[`${this.store}/find`](this.reportId);
    },
    reminders() {
      return this.reminderRecords
        .filter(
          r =>
            r.status === "open" &&
            r.siteId == this.report.siteId &&
            r.raType == STORE_TO_RA_TYPE[this.inspectionStore || this.store]
        )
        .sort(sortByDate("dueDate"))
        .reverse();
    },
    inspectionStore() {
      if (!this.reportId.includes(".")) return;
      return this.reportId.split(".")[0];
    },
    inspectionId() {
      if (!this.reportId.includes(".")) return;
      return this.reportId.split(".")[1];
    }
  },
  methods: {
    submit() {
      // if closed-out links to a reminder, store a draft of the reminder.
      if (this.reminderId) {
        this.$store.dispatch(`required_actions/setDraft`, {
          id: this.reminderId,
          ...this.draft()
        });
        this.$store.dispatch(`${this.store}/setMeta`, {
          id: this.reportId,
          meta: { reminderId: this.reminderId }
        });
      }

      this.$store.dispatch(`${this.store}/closeout`, this.reportId);
      this.home === "back" ? this.$router.back() : this.$router.push(this.home);
    },
    draft() {
      return {
        draft: {
          status: "completed",
          completedById: this.employee.id,
          completedBy: this.employeeFullName,
          raLinkId: this.reportId,
          raLinkType: this.inspectionStore || STORE_TO_TYPE[this.store]
        }
      };
    }
  },
  mounted() {
    this.$store.dispatch("required_actions/fetchAll");
  }
};
</script>
