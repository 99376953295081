<template>
  <div class="px-3">
    <!-- alerts -->
    <v-alert
      v-for="(alert, index) in alerts"
      :key="index"
      :value="true"
      type="error"
      dismissible
      outlined
    >
      {{ alert }}
    </v-alert>

    <!--  attachments list -->
    <div class="column pt-3">
      <template v-for="attachment in attachments">
        <div
          class="column-item pa-2"
          :key="attachment.id"
          v-if="!attachment.destroy"
        >
          <div class="column-item-thumb">
            <FileThumb :attachment="attachment" />
          </div>
          <div class="column-item-details pl-3">
            <p class="body-2 text-truncate file-name">
              {{ attachment.fileName }}
            </p>
            <v-textarea
              no-resize
              rows="3"
              :value="attachment.notes"
              @change="value => updateNote(attachment.id, value)"
              placeholder="Add comment, description or notes here."
            ></v-textarea>
            <v-btn
              icon
              small
              class="ma-0 delete"
              @click="deleteAttachment(attachment)"
            >
              <v-icon color="red lighten-2">mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </div>
    <FileSelect ref="fileSelect" @update="handleFileUpload" :multiple="true" />
    <v-btn
      color="primary"
      dark
      fab
      small
      fixed
      bottom
      right
      @click="$refs.fileSelect.$emit('open')"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </div>
</template>

<script>
import FileSelect from "@/components/FileSelect";
import FileThumb from "@/components/FileThumb";
import onLine from "@/mixins/onLine";
import { putFile } from "@/store/libs/fileDb";

const FILE_SIZE_LIMIT = 15728640;

const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  components: { FileSelect, FileThumb },
  mixins: [onLine],
  props: ["recordId", "recordType", "storeName"],
  data() {
    return {
      alerts: []
    };
  },
  computed: {
    attachments() {
      return this.$store.getters["form_attachments/forRecord"]({
        id: this.recordId,
        type: this.recordType
      });
    }
  },
  methods: {
    updateNote(id, notes) {
      this.$store.dispatch("form_attachments/update", {
        id,
        attributes: { notes }
      });
    },
    openFileDialog() {
      this.$refs.fileupload.dispatchEvent(new MouseEvent("click"));
    },
    async handleFileUpload(files) {
      files = Array.from(files);

      // identify large files
      files
        .filter(f => f.size >= FILE_SIZE_LIMIT)
        .forEach(f => this.alerts.push(`15MB File size exceeded: ${f.name}`));

      files = files.filter(f => f.size < FILE_SIZE_LIMIT);

      for (let idx in files) {
        let base64 = await fileToBase64(files[idx]);
        try {
          files[idx].dbKey = await putFile(base64);
        } catch (error) {
          if (error.inner.name === "QuotaExceededError") {
            console.log(error);
            this.$store.commit("snackbar/error", "Failed: Not enough Storage.");
            return;
          } else {
            throw error;
          }
        }
      }

      const records = files.map(file => {
        return {
          fileName: file.name,
          fileType: file.type,
          reportId: this.recordId,
          reportType: this.recordType,
          dbKey: file.dbKey,
          notes: ""
        };
      });

      this.$store.dispatch("form_attachments/insert", records);
    },
    deleteAttachment(attachment) {
      this.$store.dispatch("form_attachments/destroy", attachment.id);
    }
  }
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5in;
}

.column-item:nth-child(1) {
  border-top: thin solid lightgrey;
}

.column-item {
  border-bottom: thin solid lightgrey;
}

.column-item-thumb {
  width: 30%;
  float: left;
}

.column-item-details {
  width: 70%;
  float: left;
  position: relative;
}

.delete {
  position: absolute;
  right: -6px;
  top: -6px;
}

.file-name {
  padding-right: 20px;
}
</style>
