<template>
  <v-app>
    <div v-if="!onLine" id="offlineIndicator"></div>
    <Snackbar />
    <LowStorage />
    <ClearAllDataDialog />
    <NavDrawer />
    <Updater :clickHandler="refreshApp" :updateExists="updateExists" />
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-progress-linear
      id="app-progress-bar"
      :indeterminate="true"
      :active.sync="showProgress"
      @click="$store.commit('resetProgress')"
    ></v-progress-linear>
  </v-app>
</template>

<script>
//import seLog from "./common/seLog";
// components
import Snackbar from "./components/Snackbar";
import LowStorage from "./components/LowStorage";
import NavDrawer from "./components/NavDrawer";
import Updater from "./components/Updater";
import onLine from "@/mixins/onLine";
import ClearAllDataDialog from "./components/ClearAllDataDialog";
import emitter from "@/events";
import { clientIsCompatible } from "@/common/compatible";

export default {
  mixins: [onLine],
  name: "App",
  components: {
    Snackbar,
    LowStorage,
    NavDrawer,
    Updater,
    ClearAllDataDialog
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    };
  },
  computed: {
    showProgress() {
      return this.$store.state.progress.inProgress > 0;
    },
    clientIsCompatible
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
  created() {
    // setup listener for xhr inprogress
    emitter.on("xhrStart", () => this.$store.commit("showProgress"));
    emitter.on("xhrStop", () => this.$store.commit("hideProgress"));
    emitter.on("authTokenRefresh", newToken =>
      this.$store.commit("updateToken", newToken)
    );

    // prompt users to update if out of date
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (
      typeof navigator.serviceWorker != "undefined" &&
      navigator.serviceWorker != null
    ) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }

    if (!clientIsCompatible()[0]) {
      this.$router.push("/signin");
    }
  },
  beforeUpdate() {
    this.$store.dispatch("storageEstimate");
  }
};
</script>

<style>
#app-progress-bar {
  margin: 0px;
  position: fixed;
  bottom: 0px;
}

#offlineIndicator {
  position: fixed;
  top: 0px;
  height: 3px;
  width: 100%;
  background-color: #ef9a9a;
  z-index: 100;
}
</style>
