<template>
  <div id="flhas-list">
    <app-toolbar title="FLHAs" extended>
      <template v-slot:extension>
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          dark
          color="error"
          elevation="0"
          @click="createNew"
          data-cy="newFlha"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </template>
    </app-toolbar>
    <EmptyList v-if="utils.isEmpty(flhas)" />

    <v-list two-line class="py-0 borders-between">
      <v-list-item
        :key="flha.id"
        v-for="flha in flhas"
        :class="{ draft: isDraft(flha) }"
      >
        <v-list-item-content @click="handleClick(flha.id)">
          <v-list-item-title>
            {{ siteName(flha.siteId) }}
            <span v-if="flha.location"> &mdash; {{ flha.location }}</span>
          </v-list-item-title>
          <v-list-item-subtitle class="red--text text--lighten-3">{{
            flha.createdAt | date
          }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar>
          <ActionsMenu
            v-if="isDraft(flha)"
            :recordId="flha.id"
            module="flhas"
          />
          <v-icon v-else color="grey lighten-2">mdi-lock</v-icon>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import ActionsMenu from "@/components/ActionsMenu";
import EmptyList from "@/components/EmptyList";
import { sortCreatedAt } from "@/store/libs/utils";
import { mapActions } from "vuex";
import { siteName } from "@/store/sites";

export default {
  components: { EmptyList, ActionsMenu },
  computed: {
    records() {
      return this.$store.getters["flhas/records"];
    },
    flhas() {
      return [...this.records].sort(sortCreatedAt).reverse();
    }
  },
  methods: {
    ...mapActions("flhas", ["fetchRecent"]),
    createNew() {
      this.$store.dispatch("flhas/create").then(record => {
        this.$router.push(`/flhas/edit/${record.id}`);
      });
    },
    meta(id) {
      return this.$store.getters["flhas/meta"](id);
    },
    handleClick(id) {
      if (!this.meta(id).draft) {
        this.$router.push(`/flhas/${id}`);
      } else {
        this.$router.push(`/flhas/edit/${id}`);
      }
    },
    isDraft(record) {
      return (this.meta(record.id) || {}).draft;
    },
    siteName
  },
  created() {
    this.fetchRecent();
  }
};
</script>
