import extendBaseStore from "@/store/libs/base";
import uuidv4 from "uuid/v4";
import Vue from "vue";

export class Problem {
  constructor(type, typeId, errors) {
    this.id = uuidv4();
    this.type = type;
    this.typeId = typeId;
    this.errors = errors;
    this.createdAt = new Date().toISOString();
  }
}

export default extendBaseStore({
  mutations: {
    destroyAll(state) {
      Vue.set(state, "records", {});
    }
  },
  actions: {
    new({ dispatch }, { type, typeId, errors }) {
      const problem = new Problem(type, typeId, errors);
      dispatch("insert", problem);
      return problem;
    },
    destroyAll({ commit }) {
      commit("destroyAll");
    }
  }
});
