import Vue from "vue";
import $xhr from "@/xhr";
import get from "lodash/get";
import extendBaseStore from "./libs/base";
import S3Upload from "@/common/s3upload.js";
import { deleteFile, getFile } from "@/store/libs/fileDb";
import { notifyRequestError } from "@/bugsnag";
import { forRecord } from "@/store/libs/utils";

function forRecordFilter(record, type, id) {
  return record.reportType === type && record.reportId == id;
}

function attachmentPayload(record, uploaderResult) {
  return {
    attachment: {
      uuid: record.id,
      report_id: record.reportId,
      report_type: record.reportType,
      file: JSON.stringify({
        id: uploaderResult.key,
        storage: "cache",
        metadata: {
          size: uploaderResult.blob.size,
          mime_type: record.fileType,
          filename: record.fileName
        }
      })
    }
  };
}
export default extendBaseStore({
  actions: {
    async persist({ dispatch, getters, rootGetters }, id) {
      // Get record from store
      const record = getters["find"](id);
      if (!record) return Error("Not Found");

      // get data url from indexDb
      const { url } = await getFile(record.dbKey);
      if (!url) return Error("indexDb: Not Found");

      // upload attachment to S3
      const uploader = new S3Upload(url, record.fileName);
      const uploaderResult = await uploader.save();

      if (uploaderResult.error) {
        notifyRequestError(uploaderResult.error);

        const tryCount = rootGetters["autosync/tryCount"](id);
        const retryLimit = process.env.VUE_APP_RETRY_LIMIT || 5;

        if (tryCount > retryLimit) {
          const problem = {
            type: "form_attachment",
            typeId: id,
            errors: uploaderResult.error
          };
          dispatch("problems/new", problem, { root: true });
          return;
        } else {
          throw uploaderResult.error;
        }
      }

      // post the payload to back-end
      const payload = attachmentPayload(record, uploaderResult.result);
      const result = await $xhr.post("/form_attachments", payload);

      if (!(result instanceof Error)) {
        // insert new record/delete old
        const attributes = result.data.data.attributes;
        dispatch("insert", attributes);
        dispatch("destroy", id);
      } else {
        // throw Network Error so autosync will retry
        if (result.networkError) throw "Network Error";

        // server responded with an error
        const response = result.response;
        const errors = get(response, "data.errors", [response.statusText]);
        let problem = { typeId: id, type: "form_attachment", errors };
        dispatch("problems/new", problem, { root: true });
        notifyRequestError(result);
      }
    }
  },
  mutations: {
    destroy(state, id) {
      // clean up any related data in IndexDb
      let record = state.records[id];
      if (record.dbKey) {
        deleteFile(record.dbKey);
      }
      Vue.delete(state.records, id);
    }
  },
  getters: {
    forRecord: forRecord(forRecordFilter)
  }
});
