import extendBaseStore from "@/store/libs/base";
import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

export default extendBaseStore({
  state: {
    unitNumbers: []
  },
  actions: { ...actions },
  mutations: { ...mutations },
  getters: { ...getters }
});
