<template>
  <v-alert
    v-if="draftCount > 0 || todoCount > 0"
    :value="true"
    :color="bgColor"
    dark
    class="pb-0 pt-2 px-2"
  >
    <div class="title font-weight-light">{{ title }}</div>
    <v-layout>
      <v-flex xs6>
        <div v-if="draftCount" class="mt-2 mb-3">
          <v-btn color="blue lighten-1" :to="to">
            {{ draftCount }}
            Draft{{ draftCount > 1 ? "s" : "" }}
            <v-icon>mdi-arrow-right-bold</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs6>
        <div v-if="todoCount" class="mt-2 mb-3">
          <v-btn color="blue lighten-1" to="/reminders">
            {{ todoCount }}
            Todo{{ todoCount > 1 ? "s" : "" }}
            <v-icon>mdi-arrow-right-bold</v-icon>
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-alert>
</template>

<script>
export default {
  props: {
    draftCount: {
      type: Number,
      required: true
    },
    todoCount: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    to: {
      required: true
    },
    bgColor: {
      type: String,
      default: "blue"
    }
  }
};
</script>
