<template>
  <div id="edit-flha">
    <app-toolbar title="Edit FLHA" extended>
      <template v-slot:left>
        <v-btn text icon @click="handleBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <template v-slot:right>
        <v-btn
          small
          color="red"
          class="white--text"
          @click="jobCompletionDialog = true"
          :disabled="!canClose"
          data-cy="closeOutButton"
        >
          Close
        </v-btn>
      </template>
      <template v-slot:extension>
        <v-tabs v-model="activeTab" slider-color="pink" grow>
          <v-tab>General</v-tab>
          <v-tab>Signatures</v-tab>
          <v-tab>Attachments</v-tab>
        </v-tabs>
      </template>
    </app-toolbar>

    <div v-if="record">
      <DiscardRecordDialog v-bind="discardDialog" />
      <!-- Job Completion -->
      <v-dialog :value="jobCompletionDialog" fullscreen>
        <app-toolbar title="FLHA Job Completion">
          <template v-slot:right>
            <v-btn icon @click="jobCompletionDialog = false"
              ><v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </app-toolbar>

        <v-card>
          <v-card-text class="pa-4 pt-12" height="100%">
            <!-- Any Incidents/Near Misses/Indicators : Yes/No -->
            <v-form v-model="jobCompletionFormValid">
              <YesNoInput
                label="Were there any incidents/injuries/near misses?"
                v-model="jobCompletionFields.incidents"
                :rules="requiredYesNo"
                @input="e => clearTextarea(e, 'incidentsExplained')"
              />

              <!-- Any Incidents/Near Misses/Indicators Explained: Text Area-->
              <v-textarea
                :disabled="jobCompletionFields.incidents !== true"
                label="Explain"
                v-model="jobCompletionFields.incidentsExplained"
                rows="2"
                auto-grow
                no-resize
                :rules="conditionalyRequired('incidents')"
              />

              <!-- Hazards Remaining : Yes/No -->
              <YesNoInput
                label="Are there hazards remaining?"
                v-model="jobCompletionFields.hazardsRemaining"
                :rules="requiredYesNo"
                @input="e => clearTextarea(e, 'hazardsRemainingExplained')"
              />

              <!-- Hazards Remaining Explained: TextArea -->
              <v-textarea
                :disabled="jobCompletionFields.hazardsRemaining !== true"
                label="Explain"
                v-model="jobCompletionFields.hazardsRemainingExplained"
                rows="2"
                auto-grow
                no-resize
                :rules="conditionalyRequired('hazardsRemaining')"
              />

              <!-- Permits Closed: Yes/No -->
              <YesNoInput
                label="All permits closed out?"
                v-model="jobCompletionFields.allPermitsClosedOut"
                :rules="requiredYesNo"
              />

              <!-- Workarea Cleaned: Yes/No -->
              <YesNoInput
                label="Work area cleaned end of shift?"
                v-model="jobCompletionFields.workAreaCleaned"
                :rules="requiredYesNo"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" text @click="jobCompletionDialog = false"
              >Cancel</v-btn
            >
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="closeOutDialog = true"
              :disabled="!jobCompletionFormValid"
              >Close Out</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <CloseOutDialog
        v-if="closeOutDialog"
        :reportId="this.recordId"
        store="flhas"
        home="/flhas/list"
        @dismissCloseout="closeOutDialog = false"
      />

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <GeneralFormTab />
        </v-tab-item>
        <v-tab-item>
          <SignaturesTab v-bind="recordInfo" />
        </v-tab-item>
        <v-tab-item>
          <AttachmentsTab v-bind="recordInfo" />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <NotFound v-else />
  </div>
</template>

<script>
import GeneralFormTab from "../components/GeneralFormTab";
import SignaturesTab from "@/components/SignaturesTab";
import DiscardRecordDialog from "@/components/DiscardRecordDialog";
import AttachmentsTab from "@/components/AttachmentsTab";
import CloseOutDialog from "@/components/CloseOutDialog";
import YesNoInput from "@/domains/flhas/components/YesNoInput";
import NotFound from "@/components/NotFound";

export default {
  components: {
    GeneralFormTab,
    SignaturesTab,
    AttachmentsTab,
    DiscardRecordDialog,
    CloseOutDialog,
    YesNoInput,
    NotFound
  },
  props: ["recordId"],
  data: function() {
    return {
      formValid: false,
      activeTab: 0,
      jobCompletionDialog: false,
      closeOutDialog: false,
      jobCompletionFormValid: false,
      requiredYesNo: [v => v === true || v === false || "Required"],
      discardDialog: {
        show: false,
        title: "Incomplete FLHA",
        body: "Site is required.",
        handleDiscard: this.discardRecord,
        handleContinue: () => (this.discardDialog.show = false)
      },
      jobCompletionFields: {
        incidents: null,
        incidentsExplained: null,
        hazardsRemaining: null,
        hazardsRemainingExplained: null,
        allPermitsClosedOut: null,
        workAreaCleaned: null
      }
    };
  },
  watch: {
    jobCompletionFields: {
      handler() {
        this.$store.commit("flhas/update", {
          id: this.recordId,
          attributes: { ...this.jobCompletionFields }
        });
      },
      deep: true
    }
  },
  methods: {
    handleBack() {
      if (this.record && !this.record.siteId) {
        this.discardDialog.show = true;
        return;
      } else {
        this.$router.push("/flhas");
      }
    },
    discardRecord() {
      this.$store.dispatch("flhas/removeRecord", this.recordId);
      this.$router.push("/flhas");
    },
    conditionalyRequired(attribute) {
      if (this.record[attribute] === true) {
        return [v => !!v || "Required"];
      } else {
        return [];
      }
    },
    clearTextarea(value, attribute) {
      if (value === false) this.jobCompletionFields[attribute] = "";
    }
  },
  computed: {
    record() {
      return this.$store.state.flhas.records[this.recordId];
    },
    taskSteps() {
      return this.$store.getters["taskSteps/forRecord"]({ id: this.recordId });
    },
    canClose() {
      return (
        this.record &&
        this.record.siteId &&
        this.taskSteps.length > 0 &&
        (this.record.hazardChoiceIds || []).length > 0
      );
    },
    recordInfo() {
      return {
        recordId: this.recordId,
        recordType: "flha",
        storeName: "flhas"
      };
    }
  }
};
</script>
