<template>
  <v-list-item @click="signOut" data-cy="signOut">
    <v-list-item-action>
      <v-icon class="fa-fw">fa-sign-out-alt</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title
        >Sign Out - {{ $store.getters.employeeFullName }}</v-list-item-title
      >
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  methods: {
    signOut() {
      this.$store.commit("signOut");
      this.$router.push("/signin");
    }
  }
};
</script>
