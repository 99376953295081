<template>
  <v-card>
    <app-toolbar title="Task Step" :menuButton="false" />
    <v-card-text class="px-4 pt-12">
      <v-form v-model="formValid">
        <v-textarea
          label="Description"
          v-model="taskStep.description"
          rows="1"
          no-resize
          auto-grow
          class="description"
          :rules="requiredRule"
          data-cy="tsDescriptionInput"
        />
        <v-textarea
          label="Hazards"
          v-model="taskStep.hazards"
          rows="1"
          no-resize
          auto-grow
          :rules="requiredRule"
          data-cy="tsHazardsInput"
        />
        <TaskStepRisk
          :severity="taskStep.severity"
          :probability="taskStep.probability"
          @inputProbability="value => (taskStep.probability = value)"
          @inputSeverity="value => (taskStep.severity = value)"
        />
        <v-textarea
          label="Control(s)"
          v-model="taskStep.precautionsTaken"
          rows="1"
          no-resize
          auto-grow
          class="mt-8"
          :rules="requiredRule"
          data-cy="tsControlsInput"
        />
        <TaskStepRisk
          :severity="taskStep.mitigatedSeverity"
          :probability="taskStep.mitigatedProbability"
          @inputProbability="value => (taskStep.mitigatedProbability = value)"
          @inputSeverity="value => (taskStep.mitigatedSeverity = value)"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="$emit('close')" color="red" class="mr-3"
        >Cancel</v-btn
      >
      <v-btn
        @click="save"
        color="primary"
        :disabled="!formValid"
        data-cy="taskStepClose"
        >Done</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import TaskStepRisk from "./TaskStepRisk";

export default {
  components: { TaskStepRisk },
  props: ["taskStepData"],
  data() {
    return {
      formValid: false,
      requiredRule: [v => !!v || "Required"],
      taskStep: { ...this.$props.taskStepData }
    };
  },
  computed: {
    isPersisted() {
      return Object.keys(this.$store.state.taskSteps.records).includes(
        this.taskStep.id
      );
    }
  },
  methods: {
    ...mapActions("taskSteps", ["update", "insert"]),
    save() {
      if (this.isPersisted) {
        this.update({ id: this.taskStep.id, attributes: this.taskStep });
      } else {
        this.insert(this.taskStep);
      }

      this.$emit("close");
    }
  }
};
</script>
