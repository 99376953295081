export function drafts(state) {
  const drafts = [];

  for (const metaId in state.meta) {
    if (state.meta[metaId].draft) {
      drafts.push(state.records[metaId]);
    }
  }

  return drafts;
}

export function siteInspectionDrafts(state, getters) {
  return getters.drafts.filter(rec => rec.type === "site_inspection");
}

export function qcInspectionDrafts(state, getters) {
  return getters.drafts.filter(
    rec => rec.type === "quality_control_inspection"
  );
}

export function eqInspectionDrafts(state, getters) {
  return getters.drafts.filter(rec => rec.type === "inspection");
}
