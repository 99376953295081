<template>
  <div class="task-step-risk" :data-risk="riskClass">
    <v-select
      :value="severity"
      :items="severityOptions"
      label="Severity"
      outlined
      class="ma-4"
      @input="value => $emit('inputSeverity', value)"
      hide-details
      dense
    />

    <v-select
      :value="probability"
      :items="propabilityOptions"
      label="Probability"
      outlined
      class="ma-4"
      @input="value => $emit('inputProbability', value)"
      hide-details
      dense
    />
  </div>
</template>

<script>
export default {
  props: {
    severity: {
      type: Number,
      required: true
    },
    probability: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      severityOptions: [
        { text: "Minor", value: 1 },
        { text: "Moderate", value: 2 },
        { text: "Serious", value: 3 },
        { text: "Major", value: 4 },
        { text: "Catastrophic", value: 5 }
      ],

      propabilityOptions: [
        { text: "Improbable", value: 1 },
        { text: "Remote", value: 2 },
        { text: "Occasional", value: 3 },
        { text: "Probable", value: 4 },
        { text: "Frequent", value: 5 }
      ]
    };
  },
  computed: {
    totalRisk() {
      return this.severity * this.probability;
    },
    riskClass() {
      let riskClass = "";
      switch (true) {
        case this.totalRisk < 5:
          riskClass = "low";
          break;
        case this.totalRisk < 10:
          riskClass = "med";
          break;
        case this.totalRisk < 20:
          riskClass = "warn";
          break;
        case this.totalRisk <= 25:
          riskClass = "high";
          break;
      }
      return riskClass;
    }
  }
};
</script>

<style>
.task-step-risk {
  border-left: 8px solid silver;
}

.task-step-risk[data-risk="high"] {
  border-color: firebrick;
}
.task-step-risk[data-risk="warn"] {
  border-color: orangered;
}
.task-step-risk[data-risk="med"] {
  border-color: yellow;
}
.task-step-risk[data-risk="low"] {
  border-color: green;
}
</style>
