import Vue from "vue";
Vue.config.devtools = process.env.NODE_ENV === "development";
Vue.config.productionTip = false;

import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// modules
import global from "./global";
import autosync from "./autosyncStore";
import session from "./modules/session";
import sites from "./sites";
import locations from "./locations";
import employees from "./employees";
import snackbar from "./modules/snackbar";
import signons from "./signons";
import form_attachments from "./form_attachments";
import required_actions from "./required_actions";
import progress from "./progress";
import navDrawer from "./navDrawer";
import comments from "./comments";
import problems from "@/domains/problems/store";
import inspections from "@/domains/inspections/store";
import { assetTypes, assets } from "@/domains/inspections/store/assets";
import customForms from "@/domains/inspections/store/customForms.js";

import safetymeetings from "@/domains/safetymeetings/store/safetymeetings";
import flhas from "./flhas";
import taskSteps from "./taskSteps";
import safetymeeting_items from "@/domains/safetymeetings/store/safetymeeting_items";
import resetAllState from "@/plugins/resetAllState";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  state: {
    storage: {
      percentageUsed: null,
      remaining: null,
      dismissed: false
    }
  },
  strict: debug,
  plugins: [resetAllState(), createPersistedState()],
  modules: {
    global,
    progress,
    navDrawer,
    problems,
    autosync,
    session,
    sites,
    locations,
    employees,
    snackbar,
    signons,
    flhas,
    taskSteps,
    safetymeetings,
    safetymeeting_items,
    form_attachments,
    required_actions,
    comments,
    customForms,
    inspections,
    assetTypes,
    assets
  },
  mutations: {
    setStorageQuota(state, data) {
      state.storage.percentageUsed = data.percentageUsed;
      state.storage.remaining = data.remaining;
      state.storage.dismissed = false;
    },
    dismissLowStorage(state) {
      state.storage.dismissed = true
    }
  },
  actions: {
    async storageEstimate({ commit }) {
      if (navigator.storage && navigator.storage.estimate) {
        const quota = await navigator.storage.estimate();
        // quota.usage -> Number of bytes used.
        // quota.quota -> Maximum number of bytes available.
        const percentageUsed = (quota.usage / quota.quota) * 100;
        const remaining = quota.quota - quota.usage;

        commit("setStorageQuota", { percentageUsed, remaining })
      }
    }
  }
});

export { store as default };
