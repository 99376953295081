export function manifestPayload(record, { signons = [], attachments = [] }) {
  signons = signons.map(({ id, name, employeeId }) => ({
    id,
    name,
    employeeId
  }));
  attachments = attachments.map(({ id, fileName, fileType, notes }) => ({
    id,
    fileName,
    fileType,
    notes
  }));

  return {
    manifest: {
      signons,
      attachments
    }
  };
}

export function updateForeignKeys(
  records,
  storeName,
  propName,
  newId,
  dispatch
) {
  for (const record of records) {
    dispatch(
      `${storeName}/update`,
      {
        id: record.id,
        attributes: {
          [propName]: newId
        }
      },
      { root: true }
    );
  }
}

export function enqueueRelated(records, store, dispatch) {
  records.forEach(record =>
    dispatch(
      "autosync/enqueue",
      { store, action: "persist", id: record.id, tries: [] },
      { root: true }
    )
  );
}

export function removeDraftRA(recType, id, { rootGetters, commit }) {
  const ra = rootGetters["required_actions/forReport"](recType, id);
  if (ra) {
    commit("required_actions/deleteDraft", ra, { root: true });
  }
}
