import RootView from "../views/Root";
import List from "../views/List";
import Edit from "../views/Edit";
import Show from "../views/Show";

export default {
  path: "/safetymeetings",
  component: RootView,
  redirect: "/safetymeetings/list",
  children: [
    {
      name: "safetymeetings",
      path: "list",
      component: List
    },
    {
      name: "edit_safetymeeting",
      path: "edit/:recordId",
      component: Edit,
      props: true
    },
    {
      name: "show_safetymeeting",
      path: ":recordId",
      component: Show,
      props: true
    }
  ]
};
