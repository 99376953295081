import { default as SafetymeetingProblem } from "./SafetymeetingProblem";
import { default as FlhaProblem } from "./FlhaProblem";
import { default as SignonProblem } from "./SignonProblem";
import { default as FormAttachmentProblem } from "./FormAttachmentProblem";
import { default as MissedReminderProblem } from "./MissedReminderProblem";
import { default as InspectionProblem } from "./InspectionProblem";

export const mapper = {
  safetymeeting: SafetymeetingProblem,
  signon: SignonProblem,
  form_attachment: FormAttachmentProblem,
  missed_reminder: MissedReminderProblem,
  flha: FlhaProblem,
  inspection: InspectionProblem
};

export const components = {
  SafetymeetingProblem,
  FlhaProblem,
  SignonProblem,
  FormAttachmentProblem,
  MissedReminderProblem,
  InspectionProblem
};

export const reportTypesMapSingularToPlural = {
  safetymeeting: "safetymeetings",
  flha: "flhas",
  inspection: "inspections",
  bbo: "bbos",
  inspection: "inspections",
  quality_control_inspection: "inspections",
  site_inspection: "inspections"
};
