import $xhr from "@/xhr";

const defaultSessionState = () => ({
  token: null,
  employee: {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    company: {
      id: null,
      name: null
    }
  }
});

export default {
  state: defaultSessionState(),
  mutations: {
    signIn: (state, { token, employee }) => {
      state.token = token;
      state.employee = employee;
    },
    signOut: state => {
      state.token = null;
    },
    updateToken: (state, newToken) => {
      state.token = newToken;
    }
  },
  actions: {
    async signIn({ commit, state }, { email, password }) {
      const payload = { auth: { email: email, password: password } };
      const result = await $xhr.post("/signin", payload);
      if (!(result instanceof Error)) {
        if (result.data.employee.email !== state.employee.email) {
          // backup existing state
          localStorage.setItem(
            `vuex-${state.employee.email}`,
            JSON.stringify(this.state)
          );

          // load any pre-existing state
          const restoredState = JSON.parse(
            localStorage.getItem(`vuex-${result.data.employee.email}`)
          );
          if (restoredState) {
            this.replaceState(restoredState);
            this.commit("resetPlugin/touch");
          } else {
            this.resetAllState();
          }
        }
        commit("signIn", result.data);

        // pre-fetch company context data
        this.dispatch("fetchOnLoad");
        return;
      } else {
        if (result.networkError) {
          throw result;
        } else if (result.response.status === 404) {
          throw result;
        } else {
          throw result;
        }
      }
    },
    signOut(context) {
      context.commit("signOut");
    }
  },
  getters: {
    authToken: state => state.token,
    employee: state => state.employee,
    employeeFullName: state =>
      `${state.employee.first_name} ${state.employee.last_name}`
  }
};
