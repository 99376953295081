<template>
  <v-card color="lighten-2" dark class="my-2">
    <v-card-text>
      <div class="mb-2">Failed to set <b>Reminder</b> as <b>missed</b>.</div>
      <span class="font-weight-thin">Problems</span>
      <div v-for="(error, index) in problem.errors" :key="index">
        {{ error }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="red" @click="remove">Dismiss</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["problem"],
  computed: {
    reminder() {
      return this.$store.state.required_actions.records[this.problem.typeId];
    }
  },
  methods: {
    remove() {
      this.$store.dispatch("problems/destroy", this.problem.id);
    }
  }
};
</script>
