<template>
  <router-view />
</template>

<script>
export default {
  created() {
    this.$store.dispatch("sites/fetchAll");
    this.$store.dispatch("locations/fetchAll");
    this.$store.dispatch("employees/fetchAll");
    this.$store.dispatch("flhas/fetchHazardOptions");
  }
};
</script>
